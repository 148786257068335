import { keyframes } from 'styled-components';

import { styled } from '@shared/ui/theme';

const anim = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const appearance = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledSkeleton = styled.div<{ $width?: number; $height?: number }>`
  height: ${({ $height }) => $height ?? 185}px;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  border-radius: 16px;
  background: linear-gradient(
    -45deg,
    ${({ theme }) => theme.colors.background.secondary.main} 0%,
    ${({ theme }) => theme.colors.transparent.primary.edit} 50%,
    ${({ theme }) => theme.colors.background.secondary.main} 100%
  );

  transition: opacity 0.3s;
  animation: ${anim} 2.5s infinite, ${appearance} 0.3s ease;
`;

type Props = {
  width?: number;
  height?: number;
};

export const Skeleton = ({ width, height }: Props) => {
  return <StyledSkeleton $width={width} $height={height} />;
};
