import { css } from 'styled-components';

import { styled, useTheme } from '@shared/ui/theme';

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  cursor: pointer;
`;

const Wrapper = styled.span`
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  transform: translateY(10px);
`;

const Line = styled.span`
  position: absolute;
  left: 3px;
  display: block;
  width: 18px;
  height: 2px;
  background: ${({ theme }) => theme.colors.content.primary.main};
  border-radius: 1px;
  transform-origin: center center;
  transition: top ${({ theme }) => theme.transition.bezier},
    transform 300ms ${({ theme }) => theme.transition.bezier},
    opacity 300ms ${({ theme }) => theme.transition.bezier};
`;

const TopLine = styled(Line)<{ $transition: string; $isActive: boolean }>`
  top: -6px;

  transition: top ${({ $transition }) => $transition},
    transform ${({ $transition }) => $transition};

  ${({ $isActive }) =>
    $isActive &&
    css`
      top: 0;
      transform: rotateZ(45deg);
    `};
`;

const MiddleLine = styled(Line)<{ $isActive: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 0;
    `}
`;

const BottomLine = styled(Line)<{ $transition: string; $isActive: boolean }>`
  top: 6px;

  transition: top ${({ $transition }) => $transition},
    transform ${({ $transition }) => $transition};

  ${({ $isActive }) =>
    $isActive &&
    css`
      top: 0;
      transform: rotateZ(-45deg);
    `};
`;

type Props = {
  isActive: boolean;
  onClick: () => void;
};

export const BurgerButton = ({ isActive, onClick }: Props) => {
  const theme = useTheme();

  const transitionDuration = isActive ? '0' : '300ms';
  const transition = `${transitionDuration} ${theme.transition.bezier}`;

  return (
    <Button onClick={onClick}>
      <Wrapper>
        <TopLine $isActive={isActive} $transition={transition} />
        <MiddleLine $isActive={isActive} />
        <BottomLine $isActive={isActive} $transition={transition} />
      </Wrapper>
    </Button>
  );
};
