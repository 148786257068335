export const theme = {
  colors: {
    background: {
      primary: { main: '#FFFFFF', edit: '#121212' },
      secondary: { main: '#F5F5F5', edit: '#212121' },
    },
    transparent: {
      primary: { main: '', edit: 'rgba(18, 18, 18, 0.12)' },
      secondary: { main: '', edit: 'rgba(18, 18, 18, 0.4)' },
    },
    content: {
      primary: { main: '#121212', edit: '#FFFFFF' },
      secondary: { main: '#A0A0A0', edit: '#7A7A7A' },
      tertiary: { main: '#C4C4C4', edit: '#434343' },
    },
    digital: {
      positive: '#409D68',
      neutral: '#2579F9',
      error: '#E14340',
      stub: '#E1E1E1',
    },
  },

  shadows: {
    drop: '0px 4px 32px rgba(0, 0, 0, 0.12)',
  },

  transition: {
    bezier: ' 300ms cubic-bezier(0.23, 1, 0.32, 1)',
  },
};
