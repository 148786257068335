import { TNavLink } from '@shared/ui/core/atoms/header-nav-link/types';

import { APP_PATHS } from '../navigation';

export const navLinksData: TNavLink[] = [
  {
    path: APP_PATHS.about,
    text: 'About',
  },
  {
    path: APP_PATHS.download,
    text: 'Download',
  },
  {
    path: APP_PATHS.privacy,
    text: 'Privacy Policy',
  },
];
