export const IconDownloadAppStoreMedium = () => {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" fill="none">
      <g>
        <path
          d="M176.71 8.59297e-05H15.2983C14.7099 8.59297e-05 14.1286 8.59298e-05 13.5418 0.00328593C13.0506 0.00648593 12.5633 0.0157819 12.0674 0.0236059C10.99 0.0362488 9.91513 0.130768 8.85214 0.306342C7.79065 0.485738 6.76241 0.823951 5.80219 1.30954C4.84316 1.79924 3.96686 2.43555 3.20508 3.1954C2.43931 3.9531 1.80098 4.82891 1.31464 5.78914C0.826955 6.74746 0.488793 7.77439 0.311833 8.83445C0.13319 9.89318 0.0370612 10.9641 0.024308 12.0377C0.00941837 12.5282 0.00784596 13.0204 0 13.511V50.4938C0.00784596 50.9906 0.00941837 51.4719 0.024308 51.9689C0.0370651 53.0424 0.133194 54.1132 0.311833 55.1719C0.488304 56.2326 0.826487 57.2601 1.31464 58.2188C1.80076 59.1759 2.43918 60.0482 3.20508 60.8017C3.96397 61.5649 4.84084 62.2016 5.80219 62.6876C6.7624 63.1745 7.79053 63.5147 8.85214 63.6969C9.91531 63.871 10.9901 63.9656 12.0674 63.9798C12.5633 63.9906 13.0506 63.9969 13.5418 63.9969C14.1286 64.0001 14.71 64.0001 15.2983 64.0001H176.71C177.287 64.0001 177.873 64.0001 178.45 63.9969C178.938 63.9969 179.44 63.9906 179.929 63.9798C181.004 63.9664 182.077 63.8718 183.138 63.6969C184.203 63.5134 185.235 63.1733 186.199 62.6876C187.16 62.2014 188.036 61.5646 188.794 60.8017C189.558 60.0452 190.198 59.1736 190.69 58.2188C191.175 57.2594 191.51 56.232 191.683 55.1719C191.862 54.1131 191.962 53.0425 191.981 51.9689C191.987 51.4719 191.987 50.9906 191.987 50.4938C192 49.9126 192 49.3345 192 48.7438V15.2579C192 14.672 192 14.0907 191.987 13.511C191.987 13.0204 191.987 12.5282 191.981 12.0376C191.962 10.964 191.862 9.89326 191.683 8.83439C191.509 7.77494 191.174 6.74809 190.69 5.78908C189.7 3.8644 188.129 2.29773 186.199 1.3094C185.235 0.824994 184.203 0.486871 183.138 0.306198C182.077 0.12985 181.004 0.0352983 179.929 0.0233819C179.44 0.0155739 178.938 0.00619793 178.45 0.00307793C177.873 -0.00012207 177.287 -0.00012207 176.71 -0.00012207V8.59297e-05Z"
          fill="#A6A6A6"
        />
        <path
          d="M13.5497 62.6C13.0608 62.6 12.5838 62.5938 12.0988 62.5829C11.094 62.5698 10.0916 62.4826 9.09973 62.3219C8.17489 62.1631 7.27896 61.8676 6.44151 61.4453C5.61173 61.0265 4.85491 60.4773 4.20003 59.8187C3.53568 59.168 2.98257 58.4132 2.56263 57.5843C2.13807 56.7501 1.84424 55.8559 1.69139 54.9328C1.52633 53.941 1.43703 52.9381 1.42425 51.9328C1.41407 51.5954 1.40076 50.4719 1.40076 50.4719V13.511C1.40076 13.511 1.41494 12.4047 1.42433 12.0797C1.43656 11.076 1.52535 10.0747 1.68992 9.08439C1.84305 8.15882 2.1371 7.26202 2.5619 6.42503C2.98029 5.59673 3.53033 4.8414 4.19068 4.18831C4.85029 3.52902 5.60953 2.97699 6.44072 2.55236C7.27625 2.13137 8.17048 1.838 9.09346 1.68206C10.0886 1.51976 11.0945 1.43202 12.1027 1.41956L13.5505 1.40002H178.442L179.907 1.42034C180.906 1.43218 181.903 1.51914 182.889 1.68049C183.821 1.83838 184.724 2.13381 185.57 2.55705C187.235 3.4128 188.59 4.76669 189.444 6.42894C189.862 7.26015 190.152 8.14963 190.303 9.06721C190.47 10.0656 190.563 11.0748 190.582 12.0867C190.587 12.5399 190.587 13.0266 190.587 13.511C190.599 14.111 190.599 14.6821 190.599 15.2578V48.7437C190.599 49.325 190.599 49.8922 190.587 50.464C190.587 50.9843 190.587 51.461 190.58 51.9515C190.562 52.9454 190.47 53.9366 190.306 54.9171C190.156 55.8468 189.864 56.748 189.44 57.5891C189.017 58.409 188.467 59.1572 187.81 59.8063C187.155 60.4683 186.397 61.0207 185.565 61.4423C184.722 61.8679 183.82 62.1644 182.889 62.3219C181.897 62.4835 180.894 62.5707 179.889 62.5829C179.42 62.5938 178.927 62.6 178.45 62.6L176.71 62.6032L13.5497 62.6Z"
          fill="black"
        />
        <path
          d="M39.7414 32.4811C39.7587 31.1456 40.1144 29.8362 40.7755 28.6747C41.4366 27.5132 42.3816 26.5373 43.5225 25.8378C42.7977 24.8055 41.8415 23.956 40.7299 23.3567C39.6182 22.7575 38.3818 22.4249 37.1187 22.3855C34.4244 22.1035 31.8124 23.9932 30.4394 23.9932C29.0398 23.9932 26.9259 22.4135 24.6496 22.4602C23.1772 22.5076 21.7422 22.9346 20.4845 23.6995C19.2268 24.4644 18.1892 25.5411 17.4728 26.8248C14.3697 32.1823 16.6844 40.056 19.6569 44.3865C21.1441 46.5071 22.8822 48.8758 25.1565 48.7918C27.382 48.6998 28.2132 47.3766 30.8996 47.3766C33.561 47.3766 34.3409 48.7918 36.6613 48.7384C39.0494 48.6997 40.554 46.6084 41.989 44.4678C43.0576 42.9568 43.8798 41.2868 44.4253 39.5198C43.0379 38.9346 41.8539 37.9551 41.021 36.7034C40.1881 35.4518 39.7431 33.9833 39.7414 32.4811Z"
          fill="white"
        />
        <path
          d="M35.3585 19.5374C36.6606 17.9787 37.3021 15.9752 37.1468 13.9524C35.1575 14.1607 33.3199 15.1088 32.0003 16.6078C31.355 17.34 30.8608 18.1919 30.546 19.1148C30.2311 20.0376 30.1017 21.0133 30.1652 21.986C31.1602 21.9962 32.1445 21.7811 33.0441 21.357C33.9437 20.9329 34.735 20.3107 35.3585 19.5374Z"
          fill="white"
        />
        <path
          d="M67.8736 43.4234H60.2789L58.455 48.7937H55.2382L62.4318 28.9249H65.774L72.9675 48.7937H69.6959L67.8736 43.4234ZM61.0655 40.9453H67.0855L64.1178 32.2296H64.0348L61.0655 40.9453Z"
          fill="white"
        />
        <path
          d="M88.5032 41.5516C88.5032 46.0531 86.0871 48.9453 82.441 48.9453C81.5173 48.9935 80.5988 48.7813 79.7904 48.3331C78.9821 47.8849 78.3167 47.2187 77.8704 46.4109H77.8014V53.5859H74.8196V34.3078H77.7058V36.7172H77.7607C78.2275 35.9132 78.9042 35.2505 79.7187 34.7996C80.5331 34.3486 81.455 34.1263 82.3861 34.1562C86.0729 34.1563 88.5032 37.0625 88.5032 41.5516ZM85.4384 41.5516C85.4384 38.6188 83.9185 36.6906 81.5995 36.6906C79.3213 36.6906 77.7889 38.6594 77.7889 41.5516C77.7889 44.4703 79.3213 46.425 81.5995 46.425C83.9185 46.425 85.4384 44.5109 85.4384 41.5516Z"
          fill="white"
        />
        <path
          d="M104.492 41.5515C104.492 46.0531 102.076 48.9453 98.4294 48.9453C97.5058 48.9935 96.5872 48.7813 95.7789 48.3331C94.9706 47.8849 94.3051 47.2187 93.8588 46.4109H93.7898V53.5859H90.808V34.3078H93.6942V36.7172H93.7491C94.2159 35.9132 94.8926 35.2505 95.7071 34.7996C96.5216 34.3486 97.4434 34.1263 98.3745 34.1562C102.061 34.1562 104.492 37.0625 104.492 41.5515ZM101.427 41.5515C101.427 38.6187 99.907 36.6906 97.588 36.6906C95.3097 36.6906 93.7773 38.6594 93.7773 41.5515C93.7773 44.4703 95.3097 46.425 97.588 46.425C99.907 46.425 101.427 44.5109 101.427 41.5515H101.427Z"
          fill="white"
        />
        <path
          d="M115.059 43.2578C115.28 45.2281 117.199 46.5218 119.822 46.5218C122.335 46.5218 124.144 45.228 124.144 43.4515C124.144 41.9093 123.053 40.9858 120.471 40.353L117.889 39.7327C114.23 38.8515 112.531 37.1452 112.531 34.3764C112.531 30.9483 115.527 28.5936 119.781 28.5936C123.992 28.5936 126.878 30.9483 126.975 34.3764H123.965C123.785 32.3936 122.141 31.1968 119.739 31.1968C117.337 31.1968 115.693 32.4077 115.693 34.1702C115.693 35.5749 116.743 36.4015 119.311 37.0342L121.507 37.5717C125.595 38.5358 127.293 40.1733 127.293 43.0795C127.293 46.7967 124.324 49.1248 119.601 49.1248C115.183 49.1248 112.199 46.8514 112.006 43.2576L115.059 43.2578Z"
          fill="white"
        />
        <path
          d="M133.728 30.8796V34.3078H136.491V36.6625H133.728V44.6484C133.728 45.889 134.281 46.4671 135.496 46.4671C135.824 46.4614 136.151 46.4385 136.477 46.3983V48.739C135.931 48.8407 135.376 48.8868 134.82 48.8765C131.879 48.8765 130.732 47.7749 130.732 44.9655V36.6625H128.62V34.3078H130.732V30.8796H133.728Z"
          fill="white"
        />
        <path
          d="M138.091 41.5515C138.091 36.9937 140.782 34.1296 144.98 34.1296C149.192 34.1296 151.871 36.9936 151.871 41.5515C151.871 46.1218 149.206 48.9734 144.98 48.9734C140.756 48.9734 138.091 46.1218 138.091 41.5515ZM148.833 41.5515C148.833 38.425 147.396 36.5796 144.98 36.5796C142.564 36.5796 141.129 38.439 141.129 41.5515C141.129 44.6906 142.564 46.5218 144.98 46.5218C147.396 46.5218 148.833 44.6906 148.833 41.5515H148.833Z"
          fill="white"
        />
        <path
          d="M154.33 34.3078H157.174V36.7734H157.243C157.435 36.0033 157.887 35.3228 158.524 34.8462C159.16 34.3696 159.941 34.1259 160.737 34.1562C161.08 34.155 161.423 34.1922 161.758 34.2672V37.0484C161.325 36.9162 160.872 36.8555 160.419 36.8687C159.985 36.8512 159.554 36.9274 159.153 37.092C158.752 37.2566 158.391 37.5058 158.096 37.8224C157.801 38.139 157.578 38.5156 157.443 38.9263C157.307 39.3369 157.262 39.772 157.311 40.2015V48.7937H154.33L154.33 34.3078Z"
          fill="white"
        />
        <path
          d="M175.506 44.539C175.105 47.1687 172.537 48.9734 169.251 48.9734C165.025 48.9734 162.402 46.1499 162.402 41.6203C162.402 37.0765 165.039 34.1296 169.126 34.1296C173.145 34.1296 175.672 36.8828 175.672 41.2749V42.2937H165.412V42.4734C165.365 43.0065 165.432 43.5436 165.609 44.0489C165.785 44.5543 166.068 45.0163 166.438 45.4044C166.807 45.7925 167.255 46.0977 167.752 46.2998C168.249 46.5019 168.784 46.5962 169.32 46.5765C170.025 46.6423 170.732 46.4796 171.336 46.1125C171.941 45.7454 172.41 45.1935 172.675 44.539L175.506 44.539ZM165.426 40.2156H172.689C172.716 39.7362 172.643 39.2565 172.475 38.8066C172.307 38.3567 172.047 37.9462 171.713 37.601C171.378 37.2557 170.976 36.9832 170.531 36.8005C170.085 36.6177 169.607 36.5287 169.126 36.539C168.64 36.5361 168.159 36.6292 167.71 36.8127C167.26 36.9963 166.852 37.2668 166.508 37.6087C166.164 37.9505 165.891 38.3568 165.706 38.8043C165.52 39.2517 165.425 39.7314 165.426 40.2156V40.2156Z"
          fill="white"
        />
        <path
          d="M60.6918 13.9697C61.3169 13.925 61.9442 14.0191 62.5285 14.2454C63.1127 14.4717 63.6392 14.8245 64.0702 15.2783C64.5011 15.7321 64.8257 16.2756 65.0205 16.8696C65.2153 17.4637 65.2754 18.0934 65.1966 18.7134C65.1966 21.7634 63.5436 23.5166 60.6918 23.5166H57.2337V13.9697H60.6918ZM58.7207 22.1665H60.5257C60.9724 22.1931 61.4194 22.1201 61.8343 21.9527C62.2491 21.7853 62.6213 21.5278 62.9237 21.1989C63.2261 20.87 63.4512 20.478 63.5826 20.0514C63.7139 19.6248 63.7482 19.1744 63.683 18.7329C63.7435 18.2932 63.7057 17.8455 63.5723 17.4221C63.439 16.9986 63.2133 16.6098 62.9116 16.2835C62.6099 15.9572 62.2395 15.7015 61.827 15.5347C61.4145 15.368 60.9701 15.2942 60.5257 15.3188H58.7207V22.1665Z"
          fill="white"
        />
        <path
          d="M66.8763 19.9111C66.8309 19.4376 66.8852 18.9599 67.0357 18.5086C67.1863 18.0573 67.4298 17.6423 67.7506 17.2903C68.0714 16.9384 68.4625 16.6571 68.8986 16.4647C69.3348 16.2723 69.8065 16.1729 70.2835 16.1729C70.7604 16.1729 71.2322 16.2723 71.6683 16.4647C72.1045 16.6571 72.4955 16.9384 72.8164 17.2903C73.1372 17.6423 73.3807 18.0573 73.5312 18.5086C73.6818 18.9599 73.7361 19.4376 73.6907 19.9111C73.737 20.385 73.6833 20.8634 73.5331 21.3154C73.383 21.7675 73.1396 22.1832 72.8187 22.5358C72.4978 22.8885 72.1065 23.1703 71.6699 23.3632C71.2333 23.556 70.761 23.6557 70.2835 23.6557C69.8059 23.6557 69.3337 23.556 68.8971 23.3632C68.4605 23.1703 68.0691 22.8885 67.7482 22.5358C67.4274 22.1832 67.184 21.7675 67.0338 21.3154C66.8837 20.8634 66.83 20.385 66.8763 19.9111ZM72.2241 19.9111C72.2241 18.3493 71.5205 17.4361 70.2858 17.4361C69.0464 17.4361 68.3492 18.3493 68.3492 19.9111C68.3492 21.4853 69.0465 22.3915 70.2858 22.3915C71.5206 22.3915 72.2241 21.479 72.2241 19.9111H72.2241Z"
          fill="white"
        />
        <path
          d="M82.7489 23.5165H81.2697L79.7765 18.2102H79.6637L78.1767 23.5165H76.7117L74.7202 16.3118H76.1664L77.4606 21.8094H77.5672L79.0526 16.3118H80.4205L81.9059 21.8094H82.0187L83.3067 16.3118H84.7326L82.7489 23.5165Z"
          fill="white"
        />
        <path
          d="M86.4075 16.3118H87.7801V17.4563H87.8867C88.0674 17.0453 88.3723 16.7007 88.7588 16.4706C89.1452 16.2405 89.5941 16.1364 90.0427 16.1727C90.3943 16.1464 90.7472 16.1992 91.0755 16.3274C91.4038 16.4555 91.6989 16.6557 91.9391 16.9131C92.1793 17.1704 92.3583 17.4784 92.4629 17.8141C92.5675 18.1498 92.5951 18.5047 92.5435 18.8524V23.5164H91.1176V19.2095C91.1176 18.0517 90.6131 17.4759 89.5585 17.4759C89.3199 17.4648 89.0816 17.5053 88.8601 17.5946C88.6386 17.684 88.4391 17.82 88.2752 17.9934C88.1113 18.1668 87.987 18.3735 87.9108 18.5993C87.8345 18.8251 87.8081 19.0647 87.8334 19.3017V23.5165H86.4075L86.4075 16.3118Z"
          fill="white"
        />
        <path
          d="M94.8155 13.4993H96.2414V23.5164H94.8155V13.4993Z"
          fill="white"
        />
        <path
          d="M98.2234 19.9111C98.178 19.4376 98.2324 18.9598 98.383 18.5085C98.5336 18.0572 98.7771 17.6422 99.098 17.2902C99.4188 16.9383 99.8099 16.657 100.246 16.4646C100.682 16.2721 101.154 16.1727 101.631 16.1727C102.108 16.1727 102.58 16.2721 103.016 16.4646C103.452 16.657 103.843 16.9383 104.164 17.2902C104.485 17.6422 104.728 18.0572 104.879 18.5085C105.03 18.9598 105.084 19.4376 105.039 19.9111C105.085 20.385 105.031 20.8634 104.881 21.3155C104.731 21.7675 104.487 22.1832 104.166 22.5358C103.845 22.8885 103.454 23.1703 103.017 23.3632C102.581 23.556 102.109 23.6556 101.631 23.6556C101.153 23.6556 100.681 23.556 100.245 23.3632C99.8079 23.1703 99.4166 22.8885 99.0956 22.5358C98.7747 22.1832 98.5313 21.7675 98.3811 21.3155C98.2309 20.8634 98.1772 20.385 98.2234 19.9111ZM103.571 19.9111C103.571 18.3494 102.868 17.4361 101.633 17.4361C100.394 17.4361 99.6963 18.3494 99.6963 19.9111C99.6963 21.4853 100.394 22.3916 101.633 22.3916C102.868 22.3915 103.571 21.479 103.571 19.9111H103.571Z"
          fill="white"
        />
        <path
          d="M106.54 21.479C106.54 20.1821 107.508 19.4344 109.227 19.3282L111.184 19.2157V18.5938C111.184 17.8329 110.679 17.4032 109.705 17.4032C108.909 17.4032 108.357 17.6946 108.199 18.204H106.819C106.964 16.9665 108.132 16.1727 109.771 16.1727C111.582 16.1727 112.604 17.0719 112.604 18.5938V23.5165H111.231V22.504H111.118C110.889 22.8672 110.568 23.1632 110.186 23.362C109.805 23.5607 109.378 23.655 108.948 23.6352C108.645 23.6666 108.338 23.6344 108.048 23.5405C107.758 23.4465 107.491 23.2931 107.264 23.0899C107.037 22.8868 106.856 22.6384 106.731 22.361C106.606 22.0835 106.541 21.7831 106.54 21.479ZM111.184 20.8633V20.261L109.42 20.3735C108.425 20.4399 107.973 20.7774 107.973 21.4126C107.973 22.061 108.537 22.4383 109.313 22.4383C109.54 22.4613 109.77 22.4384 109.988 22.371C110.206 22.3037 110.409 22.1933 110.583 22.0463C110.758 21.8994 110.901 21.7189 111.004 21.5157C111.107 21.3125 111.169 21.0906 111.184 20.8633Z"
          fill="white"
        />
        <path
          d="M114.478 19.911C114.478 17.6345 115.651 16.1923 117.477 16.1923C117.928 16.1715 118.376 16.2794 118.769 16.5032C119.161 16.727 119.481 17.0576 119.692 17.4563H119.799V13.4993H121.225V23.5165H119.858V22.3782H119.745C119.518 22.7741 119.187 23.1006 118.787 23.3222C118.387 23.5437 117.934 23.652 117.477 23.6352C115.639 23.6353 114.478 22.193 114.478 19.911ZM115.95 19.911C115.95 21.4391 116.673 22.3587 117.881 22.3587C119.083 22.3587 119.825 21.4259 119.825 19.9173C119.825 18.4157 119.075 17.4696 117.881 17.4696C116.681 17.4696 115.95 18.3954 115.95 19.911H115.95Z"
          fill="white"
        />
        <path
          d="M127.124 19.9111C127.078 19.4376 127.133 18.9599 127.283 18.5086C127.434 18.0573 127.677 17.6423 127.998 17.2903C128.319 16.9384 128.71 16.6571 129.146 16.4647C129.582 16.2723 130.054 16.1729 130.531 16.1729C131.008 16.1729 131.48 16.2723 131.916 16.4647C132.352 16.6571 132.743 16.9384 133.064 17.2903C133.385 17.6423 133.628 18.0573 133.779 18.5086C133.929 18.9599 133.984 19.4376 133.938 19.9111C133.985 20.385 133.931 20.8634 133.781 21.3154C133.631 21.7675 133.387 22.1832 133.066 22.5358C132.745 22.8885 132.354 23.1703 131.918 23.3632C131.481 23.556 131.009 23.6557 130.531 23.6557C130.054 23.6557 129.581 23.556 129.145 23.3632C128.708 23.1703 128.317 22.8885 127.996 22.5358C127.675 22.1832 127.432 21.7675 127.281 21.3154C127.131 20.8634 127.078 20.385 127.124 19.9111ZM132.472 19.9111C132.472 18.3493 131.768 17.4361 130.533 17.4361C129.294 17.4361 128.597 18.3493 128.597 19.9111C128.597 21.4853 129.294 22.3915 130.533 22.3915C131.768 22.3915 132.472 21.479 132.472 19.9111Z"
          fill="white"
        />
        <path
          d="M135.851 16.3118H137.224V17.4563H137.331C137.511 17.0453 137.816 16.7007 138.203 16.4706C138.589 16.2405 139.038 16.1364 139.487 16.1727C139.838 16.1464 140.191 16.1992 140.519 16.3274C140.848 16.4555 141.143 16.6557 141.383 16.9131C141.623 17.1704 141.802 17.4784 141.907 17.8141C142.011 18.1498 142.039 18.5047 141.987 18.8524V23.5164H140.562V19.2095C140.562 18.0517 140.057 17.4759 139.002 17.4759C138.764 17.4648 138.526 17.5053 138.304 17.5946C138.082 17.684 137.883 17.82 137.719 17.9934C137.555 18.1668 137.431 18.3735 137.355 18.5993C137.278 18.8251 137.252 19.0647 137.277 19.3017V23.5165H135.851V16.3118Z"
          fill="white"
        />
        <path
          d="M150.044 14.5181V16.3446H151.61V17.5423H150.044V21.247C150.044 22.0017 150.356 22.3321 151.066 22.3321C151.248 22.3315 151.429 22.3206 151.61 22.2993V23.4837C151.354 23.5294 151.094 23.5537 150.834 23.5563C149.248 23.5563 148.617 23.0001 148.617 21.611V17.5422H147.47V16.3446H148.617V14.5181H150.044Z"
          fill="white"
        />
        <path
          d="M153.557 13.4993H154.971V17.4696H155.083C155.273 17.0547 155.586 16.7081 155.98 16.4772C156.375 16.2462 156.831 16.1421 157.286 16.179C157.636 16.16 157.986 16.2182 158.31 16.3495C158.635 16.4807 158.926 16.6818 159.164 16.9384C159.402 17.1949 159.58 17.5006 159.685 17.8336C159.791 18.1666 159.821 18.5188 159.775 18.8649V23.5165H158.347V19.2157C158.347 18.0649 157.81 17.4821 156.802 17.4821C156.557 17.462 156.311 17.4956 156.08 17.5805C155.849 17.6654 155.64 17.7995 155.467 17.9735C155.294 18.1475 155.16 18.3571 155.077 18.5877C154.993 18.8183 154.961 19.0644 154.983 19.3086V23.5164H153.557L153.557 13.4993Z"
          fill="white"
        />
        <path
          d="M168.089 21.5712C167.895 22.2296 167.475 22.7992 166.903 23.1802C166.331 23.5613 165.642 23.7295 164.958 23.6555C164.482 23.6681 164.009 23.5771 163.572 23.3891C163.134 23.2011 162.744 22.9204 162.426 22.5666C162.108 22.2127 161.872 21.7942 161.733 21.3401C161.594 20.886 161.555 20.4072 161.62 19.9368C161.557 19.465 161.596 18.9851 161.735 18.5296C161.874 18.0741 162.109 17.6537 162.425 17.2968C162.741 16.9399 163.13 16.6548 163.566 16.4609C164.002 16.2669 164.474 16.1687 164.952 16.1727C166.962 16.1727 168.175 17.5423 168.175 19.8047V20.3009H163.073V20.3806C163.051 20.6449 163.084 20.9111 163.171 21.1619C163.258 21.4127 163.396 21.6426 163.577 21.8369C163.758 22.0313 163.978 22.1857 164.223 22.2903C164.467 22.395 164.731 22.4475 164.997 22.4446C165.338 22.4854 165.684 22.4241 165.99 22.2686C166.296 22.1131 166.549 21.8703 166.716 21.5711L168.089 21.5712ZM163.073 19.2493H166.722C166.74 19.0075 166.707 18.7646 166.625 18.5363C166.544 18.3079 166.415 18.0991 166.247 17.9234C166.08 17.7476 165.877 17.6087 165.653 17.5156C165.428 17.4225 165.187 17.3773 164.944 17.3829C164.697 17.3798 164.452 17.426 164.224 17.5187C163.996 17.6114 163.788 17.7488 163.614 17.9227C163.439 18.0967 163.302 18.3036 163.209 18.5315C163.116 18.7593 163.07 19.0034 163.073 19.2493H163.073Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="192" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
