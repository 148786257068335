import { IconImage } from '@shared/ui/icons';
import { styled } from '@shared/ui/theme';
import { HeaderLarge } from '@shared/ui/typography';

const Wrapper = styled.div<{ $width?: number; $height?: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  height: ${({ $height }) => ($height ? $height : 240)}px;
  padding-top: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  background-color: ${({ theme }) => theme.colors.background.secondary.main};
`;

const Text = styled(HeaderLarge)`
  color: ${({ theme }) => theme.colors.digital.stub};
`;

type Props = {
  text?: string;
  width?: number;
  height?: number;
};

export const ImageStub = ({ text, width, height }: Props) => {
  return (
    <Wrapper $width={width} $height={height}>
      <IconImage />
      <Text>{text ?? '3x2'}</Text>
    </Wrapper>
  );
};
