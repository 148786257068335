import { QRCodeSVG } from 'qrcode.react';

import { styled, useTheme } from '@shared/ui/theme';

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  padding: 6px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.background.primary.main};
`;

type Props = {
  value: string;
  size?: number;
};

export const QrCode = ({ value, size = 76 }: Props) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <QRCodeSVG
        value={value}
        size={size}
        fgColor={theme.colors.content.primary.main}
      />
    </Wrapper>
  );
};
