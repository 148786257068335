import { resolvePath } from 'react-router-dom';

import { styled, useTheme } from '@shared/ui/theme';
import { Caption } from '@shared/ui/typography';
import { IconDownloadAppStoreSmall } from '@shared/ui/icons';
import { FooterNavLink, NavList, LogoLink } from '@shared/ui/core';
import { APP_LINKS, navLinksData } from '@shared/config/constants';

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 32px 20px;
  background-color: ${({ theme }) => theme.colors.background.primary.edit};
`;

const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const AppLink = styled.a`
  display: flex;
`;

export const FooterConnector = () => {
  const theme = useTheme();
  const year = new Date().getFullYear();

  const getPath = (path: string) => resolvePath(path).pathname;

  return (
    <Wrapper>
      <NavigationWrapper>
        <LogoLink color={theme.colors.content.primary.edit} />

        <NavList
          linkList={navLinksData}
          linkListRender={item => (
            <FooterNavLink text={item.text} path={getPath(item.path)} />
          )}
          gap={24}
        />
      </NavigationWrapper>

      <AppLink href={APP_LINKS.mobileLinks.ios}>
        <IconDownloadAppStoreSmall variant={'white'} />
      </AppLink>

      <Caption color={theme.colors.content.secondary.edit}>
        WalletPhoto &copy; {year}
      </Caption>
    </Wrapper>
  );
};
