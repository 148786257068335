import { Link } from 'react-router-dom';

import { styled, useTheme } from '@shared/ui/theme';
import { Title1 } from '@shared/ui/typography';

const StyledLink = styled(Link)<{ $color?: string }>`
  display: inline-block;
  text-decoration: none;
`;

type Props = {
  color?: string;
  onClick?: () => void;
};

export const LogoLink = ({ color, onClick }: Props) => {
  const theme = useTheme();

  return (
    <StyledLink to={'/'} onClick={onClick}>
      <Title1 color={color ?? theme.colors.content.primary.main}>
        WalletPhoto
      </Title1>
    </StyledLink>
  );
};
