export const aboutPageContent = [
  {
    title: 'Our mission',
    subtitle:
      'WalletPhoto helps people keep beautiful moments and transform them into unique cards available right from your Apple Wallet.',
    text: [
      'People used to have family photos in their wallets wishing to keep their family closer.',
      'We keep on traditions and transform them into a digital world. Using WalletPhoto you can easily add most memorable moments into your Apple Wallet, always keeping in touch with those you love.',
    ],
  },
  {
    title: 'LGBTQ+',
    text: [
      'WalletPhoto creates community that is open to all people apart their identity. Family is a place for everyone to feel comfortable and WalletPhoto totally support people around the world no matter cisgender or straight.',
      'Also, LGBTQ+ people can use WalletPhoto to simply share your local community social links in order to support the newcomers.',
    ],
  },
  {
    title: 'Making a card',
    subtitle: 'Family Template',
    text: [
      'Choose Family Template to create a card. Pick a memorable photo, write an optional title and comment and add it to your Apple Wallet right from the app for a quick access.',
      'All created cards are also stacked in your Saved cards inside the app so you can easily make some changes.',
    ],
    extraSubtitle: 'Business Template',
    extraText: [
      'Business card is a great option when you want to quickly share your contact information with others.',
      'Simply add your preferable social link to the card and it will generate it with a QR code available for scanning from your card.',
    ],
  },
  {
    title: 'Sharing a card',
    text: [
      'WalletPhoto app allows you not only to create but also share your card with others. Making a card as a memory for your loved ones or sending them a card as a gift can be a great idea.',
    ],
  },
  {
    title: 'WalletPhoto+',
    subtitle: 'Unlimited possibilities',
    text: [
      'Join the community and feel free to generate your idea using WalletPhoto+.',
      'Get unlimited card customization using Custom Template. Choose from presets and additional color options.',
      'Share your creative cards through social networks using our hashtag #walletphoto+',
    ],
  },
];
