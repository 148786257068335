import { TNavPath } from './types';

type TAppPaths = Record<string, TNavPath>;

const getPaths = <T extends TAppPaths>(o: T) => o;

export const APP_PATHS = getPaths({
  main: '/',
  about: 'about',
  download: 'download',
  privacy: 'privacy-policy',
  share: 'share/:shareId',
});
