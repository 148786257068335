import { Fragment } from 'react';

import { styled } from '@shared/ui/theme';
import { Body2, HeaderLarge, Title2 } from '@shared/ui/typography';
import { SectionTemplate } from '@shared/ui/core';
import { FooterConnector } from '@widgets/footer';

import { privacyPageContent } from '../../mock';

const ContentWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Text = styled(Body2)`
  color: ${({ theme }) => theme.colors.content.tertiary.edit};
`;

const renderText = (text: string[]) =>
  text.map((item, index) => <Text key={index}>{item}</Text>);

export const PrivacyPolicyPage = () => {
  return (
    <>
      <SectionTemplate>
        <HeaderLarge>Privacy Policy</HeaderLarge>
        <ContentWrapper>
          {privacyPageContent.map((item, index) => (
            <Fragment key={index}>
              <Title2>{item.title}</Title2>
              {renderText(item.text)}
            </Fragment>
          ))}
        </ContentWrapper>
      </SectionTemplate>

      <FooterConnector />
    </>
  );
};
