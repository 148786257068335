import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';
import { HeaderLarge } from '@shared/ui/typography';

import { ImageStub } from '../../atoms';
import { SectionTemplate } from '../section-template';

const ImageWrapper = styled.div`
  margin: 8px 0;
`;

const Image = styled.img`
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 240px;
  max-width: 600px;
  object-fit: cover;
`;

type Props = {
  title: string;
  imageSrc?: string;
  isWithImage?: boolean;
  children: ReactNode;
  footer: ReactNode;
};

export const AboutPageTemplate = ({
  title,
  imageSrc,
  isWithImage,
  children,
  footer,
}: Props) => {
  return (
    <>
      <SectionTemplate>
        <HeaderLarge>{title}</HeaderLarge>
      </SectionTemplate>

      {isWithImage && (
        <ImageWrapper>
          {imageSrc ? (
            <Image
              src={imageSrc}
              alt={'WalletPhoto'}
              width={360}
              height={240}
            />
          ) : (
            <ImageStub />
          )}
        </ImageWrapper>
      )}

      <SectionTemplate>{children}</SectionTemplate>
      {footer}
    </>
  );
};
