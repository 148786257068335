import { Link } from 'react-router-dom';

import { styled } from '@shared/ui/theme';
import { Body3 } from '@shared/ui/typography';

import { TNavLink } from '../header-nav-link/types';

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.content.secondary.main};
  transition: color ${({ theme }) => theme.transition.bezier};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.content.primary.edit};
  }
`;

type Props = TNavLink;

export const FooterNavLink = ({ path, text }: Props) => {
  return (
    <StyledLink to={path}>
      <Body3>{text}</Body3>
    </StyledLink>
  );
};
