import { Link } from 'react-router-dom';

import { styled } from '@shared/ui/theme';
import { Header } from '@shared/ui/typography';

import { TNavLink } from './types';

const StyledLink = styled(Link)`
  width: 100%;
  display: inline-block;
  padding: 16px 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.content.primary.main};
  background-color: transparent;
  transition: background-color ${({ theme }) => theme.transition.bezier};

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.background.secondary.main};
  }
`;

type Props = TNavLink;

export const HeaderNavLink = ({ path, text }: Props) => {
  return (
    <StyledLink to={path}>
      <Header>{text}</Header>
    </StyledLink>
  );
};
