import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';
import { ErrorPageTemplate } from '@shared/ui/core';

import Error400 from '@shared/ui/images/400-error.png';
import Error502 from '@shared/ui/images/502-error.png';
import CriticalError from '@shared/ui/images/critical-error.png';

const Image = styled.img<{ $height?: number; $isWithMargin?: boolean }>`
  margin: 0 auto;
  margin-bottom: ${({ $isWithMargin }) => ($isWithMargin ? 32 : 0)}px;
  display: flex;
  width: 100%;
  max-width: 600px;
  height: ${({ $height }) => $height ?? 400}px;
  object-fit: cover;
  object-position: bottom;
`;

const errorCodes = [400, 404, 502];

type TErrorInfo = {
  error: string;
  description: string;
  image: ReactNode;
};

const errorInfo: Record<number, TErrorInfo> = {
  400: {
    error: '400',
    description: 'Found not what you were looking for',
    image: (
      <Image src={Error400} alt={'error'} height={400} $isWithMargin={true} />
    ),
  },
  404: {
    error: '404',
    description: 'That page is gone',
    image: (
      <Image src={Error400} alt={'error'} height={400} $isWithMargin={true} />
    ),
  },
  502: {
    error: 'Bad Gateway',
    description: 'This is necessary for the site to work better',
    image: <Image src={Error502} alt={'error'} height={340} $height={340} />,
  },
};

type Props = {
  errorNumber: number;
};

export const ErrorPage = ({ errorNumber }: Props) => {
  if (errorCodes.includes(errorNumber)) {
    return <ErrorPageTemplate {...errorInfo[errorNumber]} />;
  }

  return (
    <ErrorPageTemplate
      error={'We carry out technical work'}
      description={'This is necessary for the site to work better'}
      image={
        <Image src={CriticalError} alt={'error'} height={428} $height={428} />
      }
    />
  );
};
