import { styled } from '@shared/ui/theme';
import { Body4, Tab, Title3, Title4 } from '@shared/ui/typography';

import { QrCode } from '../../atoms';
import { TShareCardData } from './types';

const Wrapper = styled.article<{ $bgColor: string; $fontColor: string }>`
  width: 200px;
  height: 280px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.transparent.primary.edit};
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $fontColor }) => $fontColor};
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Icon = styled.img`
  display: flex;
  width: 10px;
  height: 10px;
  object-fit: contain;
`;

const Label = styled(Tab)<{ $color: string }>`
  color: ${({ $color }) => $color};
  text-transform: uppercase;
`;

const Image = styled.img`
  display: flex;
  width: 100%;
  height: 78px;
  object-fit: cover;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px 8px;
`;

type Props = TShareCardData;

export const ShareCard = ({
  title,
  description,
  labelName,
  backgroundColor,
  foregroundColor,
  labelColor,
  stripUrl,
  iconUrl,
  barcodeMessage,
}: Props) => {
  return (
    <Wrapper $bgColor={backgroundColor} $fontColor={foregroundColor}>
      <ContentWrapper>
        <Header>
          <TitleWrapper>
            <Title4>{title}</Title4>
            {iconUrl && (
              <Icon src={iconUrl} alt={title} width={10} height={10} />
            )}
          </TitleWrapper>

          <CopyrightWrapper>
            <Label $color={labelColor}>Made by</Label>
            <Title3>WalletPhoto</Title3>
          </CopyrightWrapper>
        </Header>

        <Image src={stripUrl} alt={title} width={200} height={78} />

        <DescriptionWrapper>
          {labelName && <Label $color={labelColor}>{labelName}</Label>}
          <Body4>{description}</Body4>
        </DescriptionWrapper>
      </ContentWrapper>

      {barcodeMessage && <QrCode value={barcodeMessage} />}
    </Wrapper>
  );
};
