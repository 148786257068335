import { TShareCardData } from '@shared/ui/core/molecules/share-card/types';

type TIncomingData = {
  pass: { [key: string]: string };
};

export const mapPassDataToShareInfoUi = (data?: TIncomingData) => {
  if (!data) {
    return null;
  }

  const pass = data.pass;

  // for typization
  const cardData: TShareCardData = {
    title: pass.title,
    description: pass.description,
    labelName: pass.labelName,
    backgroundColor: pass.backgroundColor,
    foregroundColor: pass.foregroundColor,
    labelColor: pass.labelColor,
    stripUrl: pass.stripUrl,
    iconUrl: pass.iconUrl,
    barcodeMessage: pass.barcodeMessage,
  };

  return {
    cardData,
    passUrl: pass.passUrl,
  };
};
