import { useEffect, useRef, useState } from 'react';

import { styled } from '@shared/ui/theme';
import { Header, HeaderNavLink, NavList } from '@shared/ui/core';
import { navLinksData } from '@shared/config/constants';
import { useClickOutside } from '@shared/lib/hooks';

const Wrapper = styled.header<{ $height: number }>`
  position: sticky;
  top: 0;
  height: ${({ $height }) => $height}px;
  z-index: 10;
`;

const HeaderWrapper = styled.div<{ $isOpened: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ $isOpened }) => ($isOpened ? '100vh' : 'unset')};
  background-color: ${({ $isOpened, theme }) =>
    $isOpened ? theme.colors.transparent.secondary.edit : 'transparent'};

  transition: background-color ${({ theme }) => theme.transition.bezier};
`;

const ContentWrapper = styled.div<{ $height: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${({ $height }) => $height}px;
  background-color: ${({ theme }) => theme.colors.background.primary.main};
  box-shadow: ${({ theme }) => theme.shadows.drop};

  transition: all ${({ theme }) => theme.transition.bezier};
`;

const DropdownWrapper = styled.div<{
  $isOpened: boolean;
  $height: number;
  $topOffset: number;
}>`
  position: absolute;
  top: ${({ $topOffset }) => $topOffset}px;
  left: 0;
  right: 0;
  height: ${({ $isOpened, $height }) => ($isOpened ? $height : 0)}px;
  overflow: hidden;
  transition: all ${({ theme }) => theme.transition.bezier};
  z-index: 2;
`;

export const HeaderDropdownConnector = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const togglerRef = useRef<HTMLDivElement | null>(null);
  const [dropdownHeight, setDropdownHeight] = useState<number>(0);
  const [togglerHeight, setTogglerHeight] = useState<number>(0);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const allHeaderHeight = isOpened
    ? dropdownHeight + togglerHeight
    : togglerHeight;

  const onBurgerClickHandler = () => {
    setIsOpened(!isOpened);
  };

  const onCloseDropdownHandler = () => {
    setIsOpened(false);
  };

  useClickOutside({
    ref: wrapperRef,
    handler: onCloseDropdownHandler,
    flag: isOpened,
  });

  useEffect(() => {
    if (dropdownRef.current) {
      setDropdownHeight(dropdownRef.current.scrollHeight);
    }
  }, [isOpened]);

  useEffect(() => {
    if (togglerRef.current) {
      setTogglerHeight(togglerRef.current.scrollHeight);
    }
  }, []);

  return (
    <Wrapper $height={togglerHeight}>
      <HeaderWrapper $isOpened={isOpened}>
        <ContentWrapper $height={allHeaderHeight} ref={wrapperRef}>
          <div ref={togglerRef}>
            <Header
              burgerProps={{
                isActive: isOpened,
                onClick: onBurgerClickHandler,
              }}
              onLogoClick={onCloseDropdownHandler}
            />
          </div>

          <DropdownWrapper
            $isOpened={isOpened}
            $topOffset={togglerHeight}
            $height={dropdownHeight}
            ref={dropdownRef}
          >
            <NavList
              linkList={navLinksData}
              linkListRender={item => (
                <HeaderNavLink path={item.path} text={item.text} />
              )}
              onClick={onCloseDropdownHandler}
            />
          </DropdownWrapper>
        </ContentWrapper>
      </HeaderWrapper>
    </Wrapper>
  );
};
