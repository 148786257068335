import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';

import { SectionTemplate } from '../section-template';
import { IconEllipse } from '@shared/ui/icons';

const Title = styled.h1`
  margin: 0;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: -2;

  svg {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
`;

const ContentWrapper = styled(SectionTemplate)`
  padding-top: 0;
  margin-bottom: 40px;
`;

type Props = {
  title: ReactNode;
  image: ReactNode;
  children: ReactNode;
  footer: ReactNode;
};

export const MainPageTemplate = ({ title, image, children, footer }: Props) => {
  return (
    <>
      <SectionTemplate>
        <Title>{title}</Title>

        <ImageWrapper>
          <IconEllipse />
          {image}
        </ImageWrapper>
      </SectionTemplate>

      <ContentWrapper>{children}</ContentWrapper>
      {footer}
    </>
  );
};
