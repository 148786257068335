import { styled } from '@shared/ui/theme';
import { Skeleton } from '@shared/ui/core';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 32px 16px;
`;

export const SharePageSkeleton = () => {
  return (
    <Wrapper>
      <Skeleton />
      <Skeleton height={440} />
      <Skeleton />
      <Skeleton height={60} width={190} />
    </Wrapper>
  );
};
