import { useParams, resolvePath } from 'react-router-dom';

import { mapPassDataToShareInfoUi, useGetPass } from '@entities/pass';
import { APP_PATHS } from '@shared/config/navigation';
import { ErrorPage } from '@pages/error';

import { SharePage } from './share-page';
import { SharePageSkeleton } from './share-page-skeleton';

export const SharePageConnector = () => {
  const { shareId } = useParams();

  if (!shareId) {
    return <ErrorPage errorNumber={404} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ data, loading, error }] = useGetPass(shareId);

  const mappedData = mapPassDataToShareInfoUi(data);

  const linkToAboutPage = resolvePath(APP_PATHS.about).pathname;

  if (loading) {
    return <SharePageSkeleton />;
  }

  if (error && error.response) {
    return <ErrorPage errorNumber={error.response.status} />;
  }

  if (!mappedData) {
    return <ErrorPage errorNumber={404} />;
  }

  return (
    <SharePage
      cardData={mappedData.cardData}
      addToWalletLink={mappedData.passUrl}
      moreButtonLink={linkToAboutPage}
    />
  );
};
