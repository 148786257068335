import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';
import { Body1, HeaderLarge } from '@shared/ui/typography';

import { SectionTemplate } from '../section-template';

const TextWrapper = styled(SectionTemplate)`
  margin: 0 auto;
  max-width: 600px;
  gap: 4px;
`;

type Props = {
  error: string;
  description: string;
  image: ReactNode;
};

export const ErrorPageTemplate = ({ error, description, image }: Props) => {
  return (
    <>
      {image}
      <TextWrapper>
        <HeaderLarge>{error}</HeaderLarge>
        <Body1>{description}</Body1>
      </TextWrapper>
    </>
  );
};
