import styled from 'styled-components';

type TypographyBaseProps = {
  color?: string;
};

export const HeaderLarge = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: ${({ color }) => color};
`;

export const Header = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${({ color }) => color};
`;

export const Title1 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${({ color }) => color};
`;

export const Title2 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ color }) => color};
`;

export const Title3 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: ${({ color }) => color};
`;

export const Title4 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  color: ${({ color }) => color};
`;

export const Button = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`;

export const Body1 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: ${({ color }) => color};
`;

export const Body2 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`;

export const Body3 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ color }) => color};
`;

export const Body4 = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ color }) => color};
`;

export const Caption = styled.span<TypographyBaseProps>`
  font-family: 'SF-Pro', 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${({ color }) => color};
`;

export const Tab = styled.span<TypographyBaseProps>`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 6px;
  line-height: 7px;
  color: ${({ color }) => color};
`;
