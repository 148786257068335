export const IconAddToAppleWallet = () => {
  return (
    <svg width="168" height="52" viewBox="0 0 168 52" fill="none">
      <g clipPath="url(#clip0_121_2270)">
        <path
          d="M153.05 0.000552849H14.5595C13.999 0.000552849 13.4461 0.000552849 12.8887 0.00361167C12.4198 0.0066705 11.957 0.015847 11.4851 0.023494C10.4679 0.0479646 9.44007 0.11067 8.42444 0.292671C7.40269 0.473141 6.45273 0.779023 5.52262 1.25161C4.60778 1.72114 3.77236 2.32985 3.04996 3.05479C2.31993 3.7782 1.7136 4.61785 1.25084 5.53396C0.775854 6.46385 0.474982 7.41973 0.296291 8.44444C0.113018 9.45691 0.0488727 10.4801 0.0229091 11.5048C0.00916364 11.9743 0.00763636 12.4438 0 12.9134C0 13.467 0 14.0222 0 14.5835V37.4451C0 38.0095 0 38.5616 0 39.1183C0.00763636 39.5924 0.00916364 40.0528 0.0229091 40.5284C0.0488727 41.5531 0.113018 42.5778 0.296291 43.5903C0.474982 44.615 0.775854 45.5755 1.25084 46.5023C1.7136 47.4138 2.31993 48.252 3.04996 48.9708C3.77236 49.7003 4.60778 50.309 5.52109 50.7724C6.4512 51.2496 7.40116 51.5524 8.42444 51.7375C9.43854 51.9195 10.4679 51.9791 11.4851 52.0082C11.957 52.0189 12.4213 52.025 12.8887 52.025C13.4476 52.0281 14.0005 52.0281 14.561 52.0281H153.051C153.599 52.0281 154.157 52.0281 154.707 52.025C155.172 52.025 155.649 52.0189 156.115 52.0082C157.138 51.9807 158.164 51.9195 159.169 51.7375C160.193 51.5524 161.144 51.2511 162.083 50.7724C162.995 50.3075 163.831 49.7003 164.553 48.9708C165.28 48.252 165.886 47.4138 166.357 46.5023C166.826 45.5755 167.129 44.615 167.302 43.5903C167.49 42.5778 167.549 41.5531 167.586 40.5284C167.592 40.0528 167.592 39.594 167.592 39.1183C167.604 38.5631 167.604 38.011 167.604 37.4451V14.582C167.604 14.0222 167.604 13.467 167.592 12.9118C167.592 12.4423 167.592 11.9728 167.586 11.5033C167.551 10.4786 167.491 9.45385 167.302 8.44138C167.129 7.41667 166.826 6.46079 166.357 5.53091C165.885 4.61479 165.28 3.77667 164.553 3.05173C163.831 2.32679 162.997 1.71808 162.083 1.24855C161.144 0.774435 160.193 0.468553 159.169 0.289612C158.164 0.107612 157.138 0.0449058 156.115 0.0189058C155.649 0.0112587 155.172 0.00208226 154.707 -0.000976562C154.157 0.000552849 153.599 0.000552849 153.05 0.000552849Z"
          fill="#A6A6A6"
        />
        <path
          d="M153.049 0.994141C153.596 0.994141 154.152 0.994141 154.699 0.9972C155.075 1.00026 155.46 1.00638 155.838 1.01249L156.089 1.01708C156.897 1.03696 157.955 1.08438 158.997 1.27249C159.942 1.43767 160.805 1.72214 161.628 2.13661C162.451 2.56026 163.198 3.10473 163.85 3.75932C164.496 4.40167 165.042 5.15108 165.468 5.98002C165.882 6.80438 166.161 7.66391 166.323 8.62438C166.502 9.58791 166.557 10.5943 166.59 11.5165C166.596 11.9814 166.596 12.4479 166.596 12.9327C166.609 13.4803 166.609 14.0278 166.609 14.5814V37.4431C166.609 37.9998 166.609 38.5458 166.596 39.1163C166.596 39.5873 166.596 40.0431 166.59 40.4912C166.557 41.4349 166.502 42.4412 166.32 43.4216C166.16 44.3729 165.88 45.2324 165.472 46.0414C165.039 46.8811 164.493 47.6274 163.844 48.2683C163.198 48.9213 162.454 49.4643 161.631 49.8833C160.82 50.2963 159.956 50.5807 158.991 50.7566C157.99 50.9386 156.961 50.9876 156.092 51.012C155.634 51.0227 155.165 51.0289 154.7 51.0289C154.154 51.0319 153.598 51.0319 153.051 51.0319H14.5594C14.002 51.0319 13.4506 51.0319 12.8871 51.0289C12.4274 51.0289 11.9707 51.0227 11.511 51.0136C10.6374 50.9891 9.60955 50.9401 8.60002 50.7581C7.63326 50.5838 6.7734 50.2978 5.97006 49.8849C5.14686 49.4673 4.40155 48.9229 3.74482 48.2591C3.10031 47.6244 2.55813 46.8811 2.13355 46.046C1.72424 45.2461 1.44169 44.3851 1.27217 43.4093C1.08278 42.3678 1.03544 41.3079 1.01406 40.4973C1.00489 40.1792 1.00031 39.8687 0.997258 39.5552L0.992676 39.1163V37.4431V14.5814V12.9281L0.998785 12.4708C1.00184 12.1588 1.00642 11.8468 1.01558 11.5303C1.03544 10.7227 1.08431 9.66285 1.27522 8.61367C1.44322 7.65014 1.72424 6.79061 2.1366 5.98155C2.5566 5.15108 3.09878 4.4032 3.75246 3.75626C4.40308 3.1032 5.15144 2.55873 5.97158 2.13814C6.7902 1.72061 7.64853 1.43767 8.60002 1.27096C9.64468 1.08285 10.7046 1.03696 11.5003 1.01555L11.8027 1.01096C12.1677 1.00485 12.5297 0.998729 12.8932 0.9972C13.4491 0.994141 14.002 0.994141 14.5594 0.994141H153.049Z"
          fill="black"
        />
        <path
          d="M49.5616 12.4938H12.7559V38.1573H49.5616V12.4938Z"
          fill="#DEDBCE"
        />
        <path
          d="M49.1691 12.9022H13.1804V28.1917H49.1691V12.9022Z"
          fill="#40A5D9"
        />
        <rect
          x="11.3689"
          y="13.9296"
          width="39.9535"
          height="19.0871"
          fill="url(#pattern0)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1691 19.4052C49.1691 19.2813 49.1691 19.159 49.1691 19.0351C49.1691 18.9311 49.1675 18.8271 49.1645 18.7231C49.1584 18.4968 49.1446 18.2689 49.1049 18.0456C49.0637 17.8192 48.998 17.6082 48.8926 17.4017C48.7903 17.1998 48.6559 17.0132 48.4956 16.8526C48.3352 16.692 48.1504 16.5575 47.9473 16.4535C47.7411 16.3479 47.5303 16.2822 47.3043 16.2409C47.0813 16.2011 46.8537 16.1873 46.6277 16.1812C46.5238 16.1782 46.42 16.1766 46.3161 16.1766C46.1924 16.1766 46.0702 16.1766 45.9465 16.1766H45.8457H17.8157H16.403C16.2793 16.1766 16.1571 16.1766 16.0334 16.1766C15.9295 16.1766 15.8257 16.1782 15.7218 16.1812C15.4958 16.1873 15.2682 16.2011 15.0452 16.2409C14.8192 16.2822 14.6084 16.3479 14.4022 16.4535C14.2006 16.5559 14.0143 16.6905 13.8539 16.8526C13.6936 17.0132 13.5592 17.1983 13.4569 17.4017C13.3515 17.6082 13.2858 17.8192 13.2446 18.0456C13.2049 18.2689 13.1911 18.4968 13.185 18.7231C13.1819 18.8271 13.1804 18.9311 13.1804 19.0351C13.1804 19.159 13.1804 19.2813 13.1804 19.4052V20.8199V19.708V31.4677H49.1691V19.783V19.4052Z"
          fill="#FFB003"
        />
        <rect
          x="11.3689"
          y="17.2332"
          width="39.9535"
          height="19.0871"
          fill="url(#pattern1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1691 22.6812C49.1691 22.5573 49.1691 22.435 49.1691 22.3111C49.1691 22.2071 49.1675 22.1031 49.1645 21.9991C49.1584 21.7728 49.1446 21.5449 49.1049 21.3216C49.0637 21.0952 48.998 20.8842 48.8926 20.6777C48.7903 20.4758 48.6559 20.2892 48.4956 20.1286C48.3352 19.968 48.1504 19.8335 47.9473 19.7295C47.7411 19.6239 47.5303 19.5582 47.3043 19.5169C47.0813 19.4771 46.8537 19.4633 46.6277 19.4572C46.5238 19.4542 46.42 19.4526 46.3161 19.4526C46.1924 19.4526 46.0702 19.4526 45.9465 19.4526H45.8442H17.8157H16.403C16.2793 19.4526 16.1571 19.4526 16.0334 19.4526C15.9295 19.4526 15.8257 19.4542 15.7218 19.4572C15.4958 19.4633 15.2682 19.4771 15.0452 19.5169C14.8192 19.5582 14.6084 19.6239 14.4022 19.7295C14.2006 19.8319 14.0143 19.9665 13.8539 20.1286C13.6936 20.2892 13.5592 20.4743 13.4569 20.6777C13.3515 20.8842 13.2858 21.0952 13.2446 21.3216C13.2049 21.5449 13.1911 21.7728 13.185 21.9991C13.1819 22.1031 13.1804 22.2071 13.1804 22.3111C13.1804 22.435 13.1804 22.5573 13.1804 22.6812V24.0959V22.984V34.7437H49.1691V23.059V22.6812Z"
          fill="#40C740"
        />
        <rect
          x="11.3689"
          y="20.5367"
          width="39.9535"
          height="19.0871"
          fill="url(#pattern2)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1691 25.9572C49.1691 25.8333 49.1691 25.711 49.1691 25.5871C49.1691 25.4831 49.1675 25.3791 49.1645 25.2751C49.1584 25.0488 49.1446 24.8209 49.1049 24.5976C49.0637 24.3712 48.998 24.1602 48.8926 23.9537C48.7903 23.7518 48.6559 23.5652 48.4956 23.4046C48.3352 23.2441 48.1504 23.1095 47.9473 23.0055C47.7411 22.8999 47.5303 22.8342 47.3043 22.7929C47.0813 22.7531 46.8537 22.7393 46.6277 22.7332C46.5238 22.7302 46.42 22.7286 46.3161 22.7286C46.1924 22.7286 46.0702 22.7286 45.9465 22.7286H45.8442H17.8157H16.403C16.2793 22.7286 16.1571 22.7286 16.0334 22.7286C15.9295 22.7286 15.8257 22.7302 15.7218 22.7332C15.4958 22.7393 15.2682 22.7531 15.0452 22.7929C14.8192 22.8342 14.6084 22.8999 14.4022 23.0055C14.2006 23.1079 14.0143 23.2425 13.8539 23.4046C13.6936 23.5652 13.5592 23.7503 13.4569 23.9537C13.3515 24.1602 13.2858 24.3712 13.2446 24.5976C13.2049 24.8209 13.1911 25.0488 13.185 25.2751C13.1819 25.3791 13.1804 25.4831 13.1804 25.5871C13.1804 25.711 13.1804 25.8333 13.1804 25.9572V27.3719V26.26V38.0197H49.1691V26.335V25.9572Z"
          fill="#F26D5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5813 10.7181C13.0509 10.7181 10.9995 12.7723 10.9995 15.3063V27.6456H13.1805V16.1291C13.1805 16.0053 13.1805 15.8829 13.1805 15.759C13.1805 15.655 13.182 15.551 13.185 15.447C13.1911 15.2207 13.2049 14.9928 13.2446 14.7695C13.2858 14.5431 13.3515 14.3321 13.4569 14.1256C13.5592 13.9237 13.6936 13.7371 13.854 13.5765C14.0159 13.4175 14.2007 13.2829 14.4023 13.1804C14.6085 13.0749 14.8192 13.0091 15.0453 12.9678C15.2682 12.9281 15.4958 12.9143 15.7218 12.9082C15.8257 12.9051 15.9295 12.9036 16.0334 12.9036C16.1571 12.9036 16.2793 12.9036 16.403 12.9036H45.9466C46.0703 12.9036 46.1925 12.9036 46.3162 12.9036C46.42 12.9036 46.5239 12.9051 46.6277 12.9082C46.8538 12.9143 47.0813 12.9281 47.3043 12.9678C47.5303 13.0091 47.7411 13.0749 47.9473 13.1804C48.1489 13.2829 48.3352 13.4175 48.4956 13.5796C48.6559 13.7402 48.7903 13.9253 48.8927 14.1287C48.9981 14.3351 49.0637 14.5462 49.105 14.7726C49.1447 14.9958 49.1584 15.2237 49.1645 15.4501C49.1676 15.5541 49.1691 15.6581 49.1691 15.7621C49.1691 15.886 49.1691 16.0083 49.1691 16.1322V27.6487H51.3501V15.3063C51.3501 12.7723 49.2987 10.7181 46.7682 10.7181H15.5813Z"
          fill="#D9D6CC"
        />
        <rect
          x="9.16968"
          y="23.4731"
          width="44.352"
          height="19.4541"
          fill="url(#pattern3)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.2134 26.0076C40.9798 26.0076 40.7446 26.0076 40.5109 26.0091C40.3139 26.0106 40.1168 26.0122 39.9198 26.0183C39.4907 26.0305 39.0569 26.055 38.6323 26.1315C38.2016 26.2095 37.8 26.3364 37.409 26.5352C37.3586 26.5612 36.2819 27.0506 35.3136 28.5586C34.579 29.7042 33.1494 30.9185 31.1487 30.9185C29.148 30.9185 27.7184 29.7042 26.9838 28.5586C25.9651 26.9696 24.8029 26.4924 24.8869 26.5352C24.4959 26.3349 24.0942 26.2095 23.6635 26.1315C23.239 26.055 22.8052 26.029 22.376 26.0183C22.179 26.0137 21.982 26.0106 21.785 26.0091C21.5513 26.0076 21.3161 26.0076 21.0824 26.0076H11.0024V36.7073C11.0024 39.2413 13.0538 41.2956 15.5843 41.2956H46.7712C49.3016 41.2956 51.353 39.2414 51.353 36.7073V26.0076H41.2134Z"
          fill="#DEDBCE"
        />
        <path
          d="M62.9694 27.0109H65.0328L69.5016 39.3334H67.4978L66.3722 36.0039H61.63L60.4952 39.3334H58.4915L62.9694 27.0109ZM62.1416 34.4668H65.8514L64.0187 29.0527H63.9851L62.1416 34.4668Z"
          fill="white"
        />
        <path
          d="M70.6821 30.3496H72.524V31.8959H72.5668C73.1044 30.8283 74.0941 30.1799 75.3724 30.1799C77.6587 30.1799 79.1249 31.9739 79.1249 34.8415V34.8507C79.1249 37.7122 77.6495 39.5123 75.3983 39.5123C74.1277 39.5123 73.0952 38.8639 72.5668 37.8223H72.524V42.3234H70.6821V30.3496ZM77.2494 34.8507V34.8415C77.2494 32.9282 76.3361 31.7598 74.8959 31.7598C73.4969 31.7598 72.5164 32.9726 72.5164 34.8415V34.8507C72.5164 36.7135 73.5061 37.9248 74.8959 37.9248C76.3437 37.9248 77.2494 36.7456 77.2494 34.8507Z"
          fill="white"
        />
        <path
          d="M80.9438 30.3496H82.7857V31.8959H82.8285C83.3661 30.8283 84.3542 30.1799 85.6341 30.1799C87.9204 30.1799 89.3866 31.9739 89.3866 34.8415V34.8507C89.3866 37.7122 87.9113 39.5123 85.6601 39.5123C84.3894 39.5123 83.3569 38.8639 82.8285 37.8223H82.7857V42.3234H80.9438V30.3496ZM87.5096 34.8507V34.8415C87.5096 32.9282 86.5963 31.7598 85.1561 31.7598C83.7571 31.7598 82.7766 32.9726 82.7766 34.8415V34.8507C82.7766 36.7135 83.7662 37.9248 85.1561 37.9248C86.6054 37.9248 87.5096 36.7456 87.5096 34.8507Z"
          fill="white"
        />
        <path
          d="M91.0103 27.0109H92.8537V39.3334H91.0103V27.0109Z"
          fill="white"
        />
        <path
          d="M94.645 34.8752V34.866C94.645 32.0565 96.2655 30.1783 98.7824 30.1783C101.299 30.1783 102.842 31.9876 102.842 34.6871V35.311H96.49C96.5236 37.0102 97.4537 37.9998 98.9199 37.9998C100.012 37.9998 100.737 37.4369 100.968 36.7625L100.994 36.6936H102.741L102.724 36.7885C102.436 38.2139 101.052 39.5123 98.8786 39.5123C96.241 39.5123 94.645 37.7107 94.645 34.8752ZM96.5144 34.0049H101.017C100.863 32.451 99.9935 31.6909 98.7916 31.6909C97.5957 31.6909 96.6748 32.5015 96.5144 34.0049Z"
          fill="white"
        />
        <path
          d="M108.698 27.0109H110.684L112.98 36.6692H113.014L115.615 27.0109H117.421L120.022 36.6692H120.065L122.359 27.0109H124.347L121.021 39.3334H119.188L116.545 30.0422H116.5L113.847 39.3334H112.013L108.698 27.0109Z"
          fill="white"
        />
        <path
          d="M124.871 36.7793V36.7625C124.871 35.1994 126.084 34.2435 128.207 34.115L130.644 33.9698V33.2953C130.644 32.3058 130.004 31.7062 128.862 31.7062C127.796 31.7062 127.139 32.2109 126.987 32.9282L126.968 33.0062H125.23L125.238 32.9114C125.366 31.3652 126.706 30.1783 128.914 30.1783C131.106 30.1783 132.495 31.3407 132.495 33.1408V39.3319H130.644V37.9141H130.611C130.09 38.8792 129.051 39.494 127.865 39.494C126.075 39.4955 124.871 38.3943 124.871 36.7793ZM128.367 38.0196C129.662 38.0196 130.644 37.1402 130.644 35.9687V35.2682L128.453 35.4059C127.346 35.4732 126.73 35.9595 126.73 36.7196V36.738C126.73 37.5226 127.379 38.0196 128.367 38.0196Z"
          fill="white"
        />
        <path
          d="M134.513 27.0109H136.355V39.3334H134.513V27.0109Z"
          fill="white"
        />
        <path
          d="M138.95 27.0109H140.792V39.3334H138.95V27.0109Z"
          fill="white"
        />
        <path
          d="M142.411 34.8752V34.866C142.411 32.0565 144.03 30.1783 146.546 30.1783C149.063 30.1783 150.608 31.9876 150.608 34.6871V35.311H144.254C144.288 37.0102 145.218 37.9998 146.685 37.9998C147.777 37.9998 148.501 37.4369 148.732 36.7625L148.76 36.6936H150.507L150.49 36.7885C150.2 38.2139 148.818 39.5123 146.643 39.5123C144.005 39.5123 142.411 37.7107 142.411 34.8752ZM144.278 34.0049H148.782C148.628 32.451 147.759 31.6909 146.556 31.6909C145.361 31.6909 144.44 32.5015 144.278 34.0049Z"
          fill="white"
        />
        <path
          d="M152.944 36.9674V31.8179H151.655V30.3481H152.944V28.0081H154.827V30.3481H156.507V31.8179H154.827V36.8558C154.827 37.7704 155.238 38.0426 156.012 38.0426C156.209 38.0426 156.37 38.0242 156.507 38.0089V39.4344C156.293 39.468 155.945 39.5108 155.569 39.5108C153.796 39.5124 152.944 38.7614 152.944 36.9674Z"
          fill="white"
        />
        <path
          d="M64.4404 19.0916H60.7628L59.8342 21.7421H58.623L62.0319 12.483H63.1728L66.5817 21.7421H65.3705L64.4404 19.0916ZM61.1033 18.1097H64.1029L62.6535 13.9773H62.5512L61.1033 18.1097Z"
          fill="white"
        />
        <path
          d="M67.313 18.2826C67.313 16.1139 68.46 14.7022 70.2163 14.7022C71.177 14.7022 71.991 15.158 72.382 15.9151H72.4782V12.0778H73.5809V21.7406H72.5302V20.6363H72.4278C71.9925 21.4056 71.1724 21.8614 70.2163 21.8614C68.4477 21.8629 67.313 20.4574 67.313 18.2826ZM68.4539 18.2826C68.4539 19.8992 69.2099 20.8688 70.4729 20.8688C71.7283 20.8688 72.5042 19.8808 72.5042 18.2826C72.5042 16.6981 71.7222 15.6963 70.4729 15.6963C69.216 15.6963 68.4539 16.6721 68.4539 18.2826Z"
          fill="white"
        />
        <path
          d="M75.3586 18.2826C75.3586 16.1139 76.5056 14.7022 78.262 14.7022C79.2226 14.7022 80.0367 15.158 80.4277 15.9151H80.5239V12.0778H81.6266V21.7406H80.5758V20.6363H80.472C80.0367 21.4056 79.2165 21.8614 78.2605 21.8614C76.4919 21.8629 75.3586 20.4574 75.3586 18.2826ZM76.498 18.2826C76.498 19.8992 77.254 20.8688 78.517 20.8688C79.7725 20.8688 80.5483 19.8808 80.5483 18.2826C80.5483 16.6981 79.7664 15.6963 78.517 15.6963C77.2601 15.6963 76.498 16.6721 76.498 18.2826Z"
          fill="white"
        />
        <path
          d="M89.0004 13.0336V14.8246H90.5399V15.7483H89.0004V19.6636C89.0004 20.4788 89.3089 20.8245 90.0268 20.8245C90.2253 20.8245 90.3337 20.8183 90.5399 20.7985V21.7283C90.32 21.7666 90.1108 21.7926 89.8924 21.7926C88.4705 21.7926 87.8993 21.2665 87.8993 19.945V15.7483H86.7859V14.8246H87.8993V13.0336H89.0004Z"
          fill="white"
        />
        <path
          d="M91.7463 18.2826C91.7463 16.0557 92.9651 14.7022 94.9322 14.7022C96.8994 14.7022 98.1166 16.0557 98.1166 18.2826C98.1166 20.5017 96.8994 21.8629 94.9322 21.8629C92.9651 21.8629 91.7463 20.5017 91.7463 18.2826ZM96.9757 18.2826C96.9757 16.64 96.2381 15.6963 94.9307 15.6963C93.6234 15.6963 92.8857 16.64 92.8857 18.2826C92.8857 19.919 93.6218 20.8688 94.9307 20.8688C96.2381 20.8688 96.9757 19.919 96.9757 18.2826Z"
          fill="white"
        />
      </g>
      <defs>
        <pattern patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image0_121_2270"
            transform="scale(0.00884956 0.0172414)"
          />
        </pattern>
        <pattern patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image1_121_2270"
            transform="scale(0.00884956 0.0172414)"
          />
        </pattern>
        <pattern patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image2_121_2270"
            transform="scale(0.00884956 0.0172414)"
          />
        </pattern>
        <pattern patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image3_121_2270"
            transform="scale(0.008 0.0172414)"
          />
        </pattern>
        <clipPath>
          <rect width="168" height="52" fill="white" />
        </clipPath>
        <image
          width="113"
          height="58"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAArlJREFUeNrsnG1vEkEUhXeXBSpUFCW+6zdN/P8/qGk00WhFY6CWsqD3JmfS6bpLoWGXNnlOckJ32NkPPNw7MzT3JglCCKE9KN3zfWh/+rsPiGn0Ghs1D6/sW0H08czckXNdZ4BsHODavDIXel3dBDOvGAuwuuYj8wO5r/uJyGYBOryF+Vy+0Fhtis1rItCBHZvH5qfmx7ruKzJZJ5tZ/wLA3+Yz+ZdgFttATKMIdGDPzW/lFwJ6JIgAbAZkIWAO75P5RO95hP6pS6vlSOwI1FjwPprfm18pGoHYDsRv5pHGF/IyWh9rIWa6Hpgn5ncC+MH80vzQ3GNNbBTiSmvgSDzm5p9KqXOB/C8a89IxIqRSh/ja/EZROFEU5gBsVGttIjOlz+/mz+YvArnQPbUQO4q0Y21mngneSA/u6eGo2Wh0FkMtXxOxGEWbyuWmdBrWQ5/wRB5FEcgZsR2FYAogx1rK4v1IGkdjVorEvib45EdaH7sAbE1picVAPIYCW7mpzCrS6VATB5smokZBhk1mTzC7m35oyUrfgFwT+noAafTwEdkppdGkDuLOE1FrIG/850N224no7ogjAxAREBEQERCBiICIgIiACEQERAREBEQgIiAiICIgAhEBEQERARGICIgIiAiIQET3RXnN+M4NcdDetPNnn1c8YJ1cb4gTeqhQl9EexPC5ryIeWzUjCgC9nNiL/73QfxbdB8R2Ic7E4EJMakHmJYCXmjg1fxU4Lzal0LRdiB6B52IwFZPLOpB5ib53bPBGOKca934qoVYctafQCmUqFmdiU2wDcSZwiR7gnTS6CbWKh9jYLMXjh5jMIojXQMbtMP244SXe3u5kmFw1XSCVHi6lLpVW54rEypSalv4OBf/B1OsfFuQ62qkWdWtiWnFNufc9OzNualqL7hZMhBBCzeqfAAMARUSzZ7uCWKkAAAAASUVORK5CYII="
        />
        <image
          width="113"
          height="58"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAqFJREFUeNrsnNtu00AURW3HdkJKUxAX8YAqUN/4//9BCCQQlIBQUtrGSZkj7ZGmxpNLZU9baS1pK7Xr8UNWzxkn1UyWAQBAD+Q9XQP9c9OHxLwjkEZeOwdL9MJGSqnXApFJBG5c1i6NXte7ZJYRgXZ+4jJVxjqHyOEFmrwrlwvlUueiLbZLYilxz1xeKrNAJPPkcPOfF/jH5Vz5LZnNPhJ9C51I4FuXdy6nEjnV9QgcTmQjYSbvs8tH/c4q9G+srXZJNFmvXN67fHA5c3mNxKQSv6v7ZapMyyqYHzsl5prvKpcjVd6pBJ7peCLJSBxO4lpz4Ew+li6/1FKXEvlfNZaR+fC5qu+NqtLaa60bw3BY23yi99na5w+XLy5fJfJK19xsq8RaEk8kbqbjWlVKFQ5fjbm6oX+wfBE8WI5UjdF2OpKspxo0019FFbRRJA6P9+BFWlc8bk1neViNRWvwWBJPgoElApMRftEyVhc8ltA69kxSdFTiVCKn2wbCoCILFU8tmdW2Yio6vqmp9hkIySpytGs6K+46EJKJ3PkPiOKuA+HhwOc+JAISAYmARCQCEgGJgEQkAhIBiYBEJAISAYmARCQCEgGJgEQkAhIBiYBEJMJjoYycP3hDHOiNg9/7suMGm+z2hjh+DxXWZaST6N/3deBjr82IvEBbTmyL/22h/yK4DolpJS7k4FJOoiLLlsBrDZy7fJM4W2zKQtO0Eq0CL+RgLifXMZFly77t2GAb4XzSedtPxa8Vh3T4rVDmcnEuN80+EhcSl+kGtuzb75pBJaZ9sFnJx085WQQSb4nMg1e/BYrtmHGkNlrRSu+1pa7UVpeqxM6Wmrd+9gv+fdhV8X5FboIn1SY2J+Ydxyz3fmSfGWOSkPfwZAIAwLD8E2AAOBuy81Gw3f4AAAAASUVORK5CYII="
        />
        <image
          width="113"
          height="58"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAvBJREFUeNrsnFtv00AQhX1NgkkvEYW+ICQET/z//4NASEBbUi5JyMVOmJHOiMF4mzRJnVQ6RzrazdbrB3+and1KO1FEURRFUZQqvsczcaBP7aYV3NTfGaKBSlybwokbp3YDuIQreOnGV5vAjO+AZ8BycSbuwF20GSNyLxFYiufiGdo5xhYO7J0wswBAHe+JC/FTtNbvo98B5IggtwIYAZBCm4hH4jH6E9efAmpwiW2CmAHSufhC/Fw8EJ+JT9H2EZEpAe4EskIEKsAf4p9ob8XX4hvxd8AsN4FoS2gPAF+KX4tfiV9gTCGeuEhkXtw9H1ok/gJEhXYl/ih+j2f1ud+hZbUJYoHoU4DvxG/FlwD4BJCZE/efE6cApSC/IlgiROrM5cggRNt95sh7F4hABfgGvwu3yUkJcK8gK7eZmSBdxciLt4jOMf7+XzRmgXw4wBJ6CYDnyIGJg0eA+wNpu/8l2hhRqXnxk/gzQM7c0SMYiR1APHM5sABALp8P+w+XFRhEWA1tY/kMHGwjuai/IKnlww5C+dTlwLwWgdTDwfQcDOQA+bEXSmOJ66eg3Uck2kRG4GFAdrEKngBoJ3Ska4rEouFAT4Dtgkxcnuy6DWW8DqJtbPJNJlKtRWS67jSQbDuRag3k2tNAsu1E6niU8BMQIkWIFCFShEiIFCFShEgRIiFShEgRIkWIhEgRIkWIFCESIkWIFCFShEiIFCFShEgRIiFSj0RZYHzVYKod3fvbZw0v8BWOyuhvDRXey2gPon33yvHYqBiRAdTrxFrJQS/6j9xzhNguRCtONAWTIMisBnCOiUPxF4DTy6a8aNouRI3ACRgMwWQeApnV6GvFBq1i9AHjWhTH7opT7alCBA7B4gZsyk0gjgAuwgv02nce8a7iITY2C/D4BiYjB/EfkL5+qZVA0YoZVpQv51J60CXVihONEYmNS2pc69uFfzNrtx0W5NLtVMtQTowbfvO69yM7M4YgEd7xwaQo6qj1R4ABAM5x0/sxgGqpAAAAAElFTkSuQmCC"
        />
        <image
          width="125"
          height="58"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAA6CAYAAACd3VbxAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABMZJREFUeNrsnQtz0kAUhTfJ8iog2LdI7VhH6/j//5DjjLa1D22hBQrEXT13uOwECBSTEO6ZObNIKWbvt3v3EdgqJRKJRCKRKA/y1vSaJBTmLK6pxc1bcOGu07roMOJxlhqC58RMpRwzHqvQjZs3pxK+sWb2U6iEvdAxPILpuTCiQaQF2mdlAPspxYzHbchMMYu8ILr4onHFuGq8Y1xAZbyEW6q94IFxH+WQNQD+eJwQfKo/B6wRnwLiVkKpU+jxIeLxbPxo3DV+Quz+gtczKqUBfN/40HjPuIZKJVEJAjfCxdLF27LHGsET3EMlR/8ZPNU9QCzKiBN5B52khsdFvDapcZ46io1Fx/jG+Mr4GsCfF0GvAfhH41PjXVQySLjF9lGBe5SPAP1g/Mv4DmUH8KdS2ZqB05BXRnyaiIstG8Z1lK/w81LC2ZE6io3DrfFXPNdDHG1svDg93QL/bPwGrTdIsNWOWU8n4GQL+qfxd/gSzz2iRa8TPAEvIAYW8pFxCz4E/Doz9fQkx3XqKDYGP/DcHeJEQ43SC8b0KipzbPwWrVcnnKqGgEhpfYCefo/U9Q29i2ehdYJ3gduhrm383vjM+ATQm/h5JcUxneLVweMrMJxqfDpGRctsnKonCJ2DHzkm8AfGr1kq9dnvdgH+JWO8xzIfAX9n/Mn4HNBbuIYdBlqzjJj0JG6Ix1WwK7jZRsdo4T6boeqEoVNFdMQSroaKVdjYqZzl3Zgt81aFHiB4TfRwC/wLyjYagru6SWtvI2RcZy4b9RKtPa3KeE6FAtYQNFs28dl+n83mexGbOsuk9RImZsfo2ecAfgrg1YjelOamzEJOWm2OvAUVi5r0DSI2dJYdx6uAa8fuD/CJs4z1Vba2Xudqk6AvAkPLuy6Wc138e8TWrnHAu++7izR+BrfxXHUTgW869Hk9kvf0sTOxG89I994SPXxjgecB+qyxt89SO80DbiJSfhix01Z0lmZ8pn6M/6O0qcDzAj1qls334gNAqmCXqst2p3ij0HgdpfSWM3Fr473LKeyyCfQ5kzxaT5MoA9CW6SV2qB6wzqd1fAGNoo419xHW42fYhOFLszRuogj0mDtnivX+BnbNLtS/mw+32NHr4XVlpG3bw/fZDmQLDaDJ1uL+JgPPG/SoiV3AxvoDwL5B+RtjvALQBqDvsbKJLFFW6X2mQKAvCd5nqbuBXsvv2PGeXlOTu2P0GYJyxE6bEujZH+P57dC6muzY9Z0xvaQmN0oKanorU+UFeJ6hc0j8/kER8N1P2/BPwQQsW+QK9jZAd+HTnj2l/Kh1uspTGt9m6G4DcG/gqLz2aIE+OwNsnXwlEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLo26g4H5eaefKgKHOKxUoveAN+WiOdPGjlSXwzC504jRi/MA50Ak6nOtlvenbY7wj0bEPvgBkdqjgFXs8BPsAv2u99XQB0kufIiVaDTufIXajJV7MHHLye01rsV3ntNzzp1EF7JlmSJ0aKVpN7YuQ1WA7jQO8AtMIbJHk2rOhlEzn3bNgOgx5m+RRo0ctS/MxToLN+3rtodfBLnffOwWfhLzuI1rxm36S/4SJaHb5o2/VHgAEAKq1w/7wLXkMAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
