import { styled, useTheme } from '@shared/ui/theme';
import { Body2, Title2, HeaderLarge } from '@shared/ui/typography';
import {
  IconDownloadAppStoreMedium,
  IconDownloadGooglePlay,
} from '@shared/ui/icons';
import { MainPageTemplate } from '@shared/ui/core';
import { FooterConnector } from '@widgets/footer';

import { APP_LINKS } from '@shared/config/constants';
import iphoneImage from '@shared/ui/images/iphone-main-page.png';

import { mainPageContent } from '../../mock';

type TColorText = 'blue' | 'red' | 'green';

const Image = styled.img`
  width: 320px;
  height: 514px;
  margin-top: -36px;
`;

const TextWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonLink = styled.a`
  display: flex;
  margin-top: 40px;
`;

const Caption = styled(Body2)`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.content.secondary.main};
`;

export const MainPage = () => {
  const theme = useTheme();

  const colorText: Record<TColorText, string> = {
    blue: theme.colors.digital.neutral,
    red: theme.colors.digital.error,
    green: theme.colors.digital.positive,
  };

  const Title = (
    <HeaderLarge>
      Instantly <HeaderLarge color={colorText['blue']}>create</HeaderLarge>,{' '}
      <HeaderLarge color={colorText['red']}>share</HeaderLarge> and{' '}
      <HeaderLarge color={colorText['green']}>enjoy</HeaderLarge> your family
      memories
    </HeaderLarge>
  );

  const renderText = (text: string[]) =>
    text.map((item, index) => (
      <Body2 key={index} color={theme.colors.content.tertiary.edit}>
        {item}
      </Body2>
    ));

  return (
    <MainPageTemplate
      title={Title}
      image={
        <Image
          src={iphoneImage}
          alt={'WalletPhoto app'}
          width={320}
          height={514}
        />
      }
      footer={<FooterConnector />}
    >
      <>
        {mainPageContent.map((item, index) => (
          <TextWrapper key={index}>
            <Title2>{item.title}</Title2>
            {renderText(item.text)}
          </TextWrapper>
        ))}

        <ButtonsWrapper>
          <ButtonLink href={APP_LINKS.mobileLinks.ios}>
            <IconDownloadAppStoreMedium />
          </ButtonLink>
          <ButtonLink>
            <IconDownloadGooglePlay />
          </ButtonLink>
          <Caption>Coming soon...</Caption>
        </ButtonsWrapper>
      </>
    </MainPageTemplate>
  );
};
