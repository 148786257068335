import { ReactNode } from 'react';
import { css } from 'styled-components';

import { styled } from '@shared/ui/theme';

import { TNavLink } from '../../atoms/header-nav-link/types';

const Wrapper = styled.ul<{ $gap?: number }>`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${$gap}px;
    `}
`;

type TDefaultLinkItem = TNavLink;

type Select<T> = {
  linkList: T[];
  linkListRender: (payload: T) => ReactNode;
  gap?: number;
  onClick?: () => void;
};

export const NavList = <T extends TDefaultLinkItem>({
  linkList,
  linkListRender,
  gap,
  onClick,
}: Select<T>) => {
  return (
    <nav>
      <Wrapper $gap={gap}>
        {linkList.map((link, index) => (
          <li key={index} tabIndex={index + 1} onClick={onClick}>
            {linkListRender(link)}
          </li>
        ))}
      </Wrapper>
    </nav>
  );
};
