import { TBaseIcon } from './types';

export const IconImage = ({ color = '#E1E1E1', size = 136 }: TBaseIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.667 17H28.3333C22.0741 17 17 22.0741 17 28.3333V107.667C17 113.926 22.0741 119 28.3333 119H107.667C113.926 119 119 113.926 119 107.667V28.3333C119 22.0741 113.926 17 107.667 17Z"
        stroke={color}
        strokeWidth="11.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.1666 56.6667C52.861 56.6667 56.6666 52.8611 56.6666 48.1667C56.6666 43.4723 52.861 39.6667 48.1666 39.6667C43.4722 39.6667 39.6666 43.4723 39.6666 48.1667C39.6666 52.8611 43.4722 56.6667 48.1666 56.6667Z"
        stroke={color}
        strokeWidth="11.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119 85L90.6667 56.6667L28.3334 119"
        stroke={color}
        strokeWidth="11.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
