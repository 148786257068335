import { useEffect, useState } from 'react';

import { styled, useTheme } from '@shared/ui/theme';
import { Body2, Body3, Caption, Header } from '@shared/ui/typography';
import { ButtonLink, SectionTemplate, ShareCard } from '@shared/ui/core';
import { IconAddToAppleWallet } from '@shared/ui/icons';

import { TShareCardData } from '@shared/ui/core/molecules/share-card/types';

const ContentWrapper = styled(SectionTemplate)`
  align-items: center;
`;

const Title = styled(Header)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const Icon = styled.img`
  display: inline-flex;
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

const Text = styled(Body3)`
  margin: 4px 0 16px;
  max-width: 250px;
  color: ${({ theme }) => theme.colors.content.secondary.main};
  text-align: center;
`;

const Link = styled.a`
  display: flex;
  margin-top: 30px;
`;

const Footer = styled.footer`
  padding: 15px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
`;

const CopyrightText = styled.p`
  margin: 0;

  ${Body2} {
    display: block;
  }
`;

type Props = {
  cardData: TShareCardData;
  addToWalletLink: string;
  moreButtonLink: string;
};

export const SharePage = ({
  cardData,
  addToWalletLink,
  moreButtonLink,
}: Props) => {
  const theme = useTheme();
  const year = new Date().getFullYear();

  const [isWithIcon, setIsWithIcon] = useState<boolean>(false);

  useEffect(() => {
    if (cardData.iconUrl) {
      const icon = new Image();
      icon.src = cardData.iconUrl;

      const { naturalHeight, naturalWidth } = icon;

      if (naturalWidth <= 1 && naturalHeight <= 1) {
        setIsWithIcon(false);
      } else {
        setIsWithIcon(true);
      }
    }
  }, [cardData.iconUrl]);

  return (
    <>
      <ContentWrapper>
        <Title>
          {cardData.title}
          {isWithIcon && <Icon src={cardData.iconUrl} alt={cardData.title} />}
        </Title>

        <Text>
          somebody&nbsp;shared&nbsp;this&nbsp;pass&nbsp;with&nbsp;you,
          add&nbsp;it to your wallet
        </Text>

        <ShareCard {...cardData} />

        <Link href={addToWalletLink}>
          <IconAddToAppleWallet />
        </Link>
      </ContentWrapper>

      <Footer>
        <CopyrightText>
          <Body2 color={theme.colors.content.secondary.main}>
            this pass was generated with
          </Body2>
          <Body2>WalletPhoto+</Body2>
        </CopyrightText>

        <ButtonLink text={'Learn more'} path={moreButtonLink} />

        <Caption color={theme.colors.content.secondary.main}>
          WalletPhoto &copy; {year}
        </Caption>
      </Footer>
    </>
  );
};
