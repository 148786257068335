import { Fragment } from 'react';

import { styled } from '@shared/ui/theme';
import { Body2, Body3, Title2 } from '@shared/ui/typography';
import { AboutPageTemplate } from '@shared/ui/core';
import { FooterConnector } from '@widgets/footer';

import { aboutPageContent } from '../../mock';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled(Title2)`
  margin: 28px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

const Subtitle = styled(Body2)`
  color: ${({ theme }) => theme.colors.content.tertiary.edit};
`;

const Text = styled(Body3)`
  color: ${({ theme }) => theme.colors.content.tertiary.edit};
`;

export const AboutPage = () => {
  const renderText = (text: string[]) =>
    text.map((item, index) => <Text key={index}>{item}</Text>);

  return (
    <AboutPageTemplate title={'About WalletPhoto'} footer={<FooterConnector />}>
      <>
        {aboutPageContent.map((item, index) => (
          <Fragment key={index}>
            <Title>{item.title}</Title>
            <TextWrapper>
              {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
              {renderText(item.text)}
              {item.extraSubtitle && <Subtitle>{item.extraSubtitle}</Subtitle>}
              {item.extraText && renderText(item.extraText)}
            </TextWrapper>
          </Fragment>
        ))}
      </>
    </AboutPageTemplate>
  );
};
