import { Link } from 'react-router-dom';

import { styled } from '@shared/ui/theme';
import { Button } from '@shared/ui/typography';

const Wrapper = styled(Link)`
  display: inline-block;
  width: 100%;
  max-width: 400px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.background.secondary.main};
  color: ${({ theme }) => theme.colors.content.primary.main};
  transition: background-color ${({ theme }) => theme.transition.bezier};

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.transparent.primary.edit};
  }
`;

type Props = {
  text: string;
  path: string;
};

export const ButtonLink = ({ text, path }: Props) => {
  return (
    <Wrapper to={path}>
      <Button>{text}</Button>
    </Wrapper>
  );
};
