type TColor = 'white' | 'black';

type Props = {
  width?: number;
  variant?: TColor;
};

export const IconDownloadAppStoreSmall = ({
  width = 120,
  variant = 'black',
}: Props) => {
  const height = width / 3;

  const accentColor: TColor = variant === 'black' ? 'white' : 'black';
  const borderColor = variant === 'black' ? '#A6A6A6' : 'black';

  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <g>
        <path
          d="M110.135 7.92969e-06H9.53468C9.16798 7.92969e-06 8.80568 7.92975e-06 8.43995 0.00200793C8.1338 0.00400793 7.83009 0.00981793 7.521 0.0147079C6.84951 0.0226097 6.17961 0.0816842 5.5171 0.191418C4.85552 0.303541 4.21467 0.514924 3.61622 0.818418C3.0185 1.12448 2.47235 1.52218 1.99757 1.99708C1.5203 2.47064 1.12246 3.01802 0.81935 3.61817C0.5154 4.21712 0.304641 4.85895 0.19435 5.52149C0.0830109 6.18319 0.0230984 6.85253 0.01515 7.52349C0.00587 7.83009 0.00489 8.13771 0 8.44435V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6967 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H110.135C110.494 40 110.859 40 111.219 39.998C111.523 39.998 111.836 39.9941 112.141 39.9873C112.811 39.9789 113.479 39.9198 114.141 39.8105C114.804 39.6959 115.448 39.4833 116.049 39.1797C116.647 38.8758 117.193 38.4779 117.666 38.001C118.142 37.5282 118.541 36.9835 118.848 36.3867C119.15 35.7871 119.358 35.145 119.467 34.4824C119.578 33.8206 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53614C119.664 9.16993 119.664 8.80665 119.656 8.44435C119.656 8.13771 119.656 7.83009 119.652 7.52345C119.64 6.85243 119.578 6.18324 119.467 5.52145C119.358 4.85929 119.149 4.21751 118.848 3.61813C118.23 2.4152 117.252 1.43603 116.049 0.818328C115.448 0.515575 114.804 0.304248 114.141 0.191328C113.48 0.0811107 112.811 0.0220157 112.141 0.0145679C111.836 0.00968793 111.523 0.00382793 111.219 0.00187793C110.859 -0.00012207 110.494 -0.00012207 110.135 -0.00012207V7.92969e-06Z"
          fill={borderColor}
        />
        <path
          d="M8.44487 39.125C8.14019 39.125 7.84287 39.1211 7.54058 39.1143C6.91436 39.1061 6.2896 39.0516 5.67144 38.9512C5.09503 38.8519 4.53664 38.6673 4.0147 38.4033C3.49754 38.1415 3.02585 37.7983 2.6177 37.3867C2.20364 36.98 1.85891 36.5082 1.59719 35.9902C1.33258 35.4688 1.14945 34.9099 1.05419 34.333C0.951311 33.7131 0.895651 33.0863 0.887687 32.458C0.881347 32.2471 0.873047 31.5449 0.873047 31.5449V8.44434C0.873047 8.44434 0.881887 7.75293 0.887737 7.5498C0.895363 6.92248 0.950699 6.29665 1.05327 5.67773C1.14871 5.09925 1.33197 4.53875 1.59673 4.01563C1.85749 3.49794 2.2003 3.02586 2.61187 2.61768C3.02297 2.20562 3.49617 1.8606 4.01421 1.59521C4.53495 1.33209 5.09228 1.14873 5.66753 1.05127C6.28772 0.949836 6.91465 0.894996 7.54304 0.88721L8.44536 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.607 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.711 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44487 39.125Z"
          fill={variant}
        />
        <path
          d="M24.7689 20.3007C24.7796 19.466 25.0013 18.6477 25.4134 17.9217C25.8254 17.1957 26.4143 16.5858 27.1254 16.1486C26.6737 15.5035 26.0777 14.9725 25.3849 14.598C24.6921 14.2234 23.9215 14.0156 23.1343 13.991C21.455 13.8147 19.8271 14.9958 18.9714 14.9958C18.0991 14.9958 16.7816 14.0085 15.3629 14.0376C14.4452 14.0673 13.5509 14.3341 12.767 14.8122C11.9831 15.2903 11.3364 15.9632 10.89 16.7655C8.95595 20.1139 10.3985 25.035 12.2511 27.7416C13.178 29.0669 14.2613 30.5474 15.6788 30.4949C17.0658 30.4374 17.5839 29.6104 19.2582 29.6104C20.9169 29.6104 21.403 30.4949 22.8492 30.4615C24.3376 30.4374 25.2753 29.1303 26.1697 27.7924C26.8357 26.848 27.3481 25.8043 27.6881 24.6999C26.8234 24.3341 26.0855 23.722 25.5664 22.9397C25.0472 22.1574 24.7699 21.2396 24.7689 20.3007Z"
          fill={accentColor}
        />
        <path
          d="M22.0372 12.2109C22.8487 11.2367 23.2485 9.98451 23.1517 8.72028C21.9119 8.8505 20.7666 9.44306 19.9441 10.3799C19.542 10.8376 19.234 11.37 19.0378 11.9468C18.8415 12.5235 18.7609 13.1333 18.8004 13.7413C19.4206 13.7477 20.0341 13.6132 20.5947 13.3482C21.1554 13.0831 21.6486 12.6942 22.0372 12.2109Z"
          fill={accentColor}
        />
        <path
          d="M42.3022 27.1396H37.5688L36.4321 30.4961H34.4272L38.9106 18.0781H40.9936L45.477 30.4961H43.438L42.3022 27.1396ZM38.0591 25.5908H41.8111L39.9615 20.1435H39.9097L38.0591 25.5908Z"
          fill={accentColor}
        />
        <path
          d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3814 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.928 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4423H48.4302V22.9482H48.4644C48.7553 22.4457 49.1771 22.0316 49.6847 21.7497C50.1923 21.4679 50.7669 21.3289 51.3472 21.3476C53.645 21.3476 55.1597 23.164 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3023 22.9316 50.857 22.9316C49.437 22.9316 48.482 24.1621 48.482 25.9697C48.482 27.7939 49.437 29.0156 50.857 29.0156C52.3023 29.0156 53.2495 27.8193 53.2495 25.9697Z"
          fill={accentColor}
        />
        <path
          d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7706 30.621 60.1981 30.4883 59.6943 30.2082C59.1905 29.9281 58.7758 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4424H58.395V22.9483H58.4292C58.7201 22.4458 59.1419 22.0316 59.6495 21.7498C60.1571 21.4679 60.7317 21.329 61.312 21.3477C63.6099 21.3477 65.1245 23.1641 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9317 60.8218 22.9317C59.4019 22.9317 58.4468 24.1621 58.4468 25.9697C58.4468 27.794 59.4019 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8194 63.2144 25.9697H63.2144Z"
          fill={accentColor}
        />
        <path
          d="M71.7105 27.0361C71.8482 28.2676 73.0445 29.0761 74.6792 29.0761C76.2456 29.0761 77.3726 28.2675 77.3726 27.1572C77.3726 26.1933 76.6929 25.6162 75.0835 25.2207L73.4742 24.833C71.1939 24.2822 70.1353 23.2158 70.1353 21.4853C70.1353 19.3427 72.0025 17.871 74.6538 17.871C77.2778 17.871 79.0767 19.3427 79.1372 21.4853H77.2612C77.1489 20.246 76.1245 19.498 74.6274 19.498C73.1304 19.498 72.106 20.2548 72.106 21.3564C72.106 22.2343 72.7603 22.7509 74.3608 23.1464L75.729 23.4823C78.2769 24.0849 79.3355 25.1083 79.3355 26.9247C79.3355 29.248 77.4849 30.703 74.5415 30.703C71.7876 30.703 69.9282 29.2821 69.8081 27.036L71.7105 27.0361Z"
          fill={accentColor}
        />
        <path
          d="M83.3462 19.2998V21.4424H85.0679V22.9141H83.3462V27.9053C83.3462 28.6807 83.6909 29.042 84.4477 29.042C84.6521 29.0384 84.8562 29.0241 85.0591 28.999V30.4619C84.7188 30.5255 84.3729 30.5543 84.0268 30.5478C82.1938 30.5478 81.479 29.8593 81.479 28.1035V22.9141H80.1626V21.4424H81.479V19.2998H83.3462Z"
          fill={accentColor}
        />
        <path
          d="M86.0649 25.9697C86.0649 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6538 23.1211 94.6538 25.9697C94.6538 28.8262 92.9927 30.6084 90.3589 30.6084C87.726 30.6084 86.0649 28.8262 86.0649 25.9697ZM92.7602 25.9697C92.7602 24.0156 91.8647 22.8623 90.3589 22.8623C88.853 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.853 29.0762 90.3589 29.0762C91.8647 29.0762 92.7602 27.9316 92.7602 25.9697H92.7602Z"
          fill={accentColor}
        />
        <path
          d="M96.186 21.4424H97.9585V22.9834H98.0015C98.1214 22.5021 98.4034 22.0768 98.8 21.7789C99.1966 21.481 99.6836 21.3287 100.179 21.3476C100.393 21.3469 100.607 21.3702 100.816 21.417V23.1553C100.546 23.0726 100.264 23.0347 99.981 23.043C99.711 23.032 99.4418 23.0796 99.192 23.1825C98.9421 23.2854 98.7175 23.4411 98.5335 23.639C98.3496 23.8369 98.2106 24.0723 98.1262 24.3289C98.0417 24.5856 98.0139 24.8575 98.0444 25.126V30.4961H96.186L96.186 21.4424Z"
          fill={accentColor}
        />
        <path
          d="M109.384 27.8369C109.134 29.4805 107.534 30.6084 105.486 30.6084C102.852 30.6084 101.217 28.8437 101.217 26.0127C101.217 23.1729 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0518 109.488 25.7969V26.4336H103.093V26.5459C103.064 26.8791 103.105 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.732 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.861 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.785 28.8203C107.162 28.5909 107.455 28.246 107.62 27.8369L109.384 27.8369ZM103.102 25.1348H107.628C107.645 24.8352 107.6 24.5354 107.495 24.2541C107.39 23.9729 107.229 23.7164 107.02 23.5006C106.812 23.2849 106.561 23.1145 106.283 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.105 22.8351 104.805 22.8933 104.525 23.008C104.245 23.1227 103.99 23.2918 103.776 23.5054C103.562 23.7191 103.392 23.973 103.276 24.2527C103.16 24.5323 103.101 24.8321 103.102 25.1348V25.1348Z"
          fill={accentColor}
        />
        <path
          d="M37.8262 8.73101C38.2158 8.70305 38.6067 8.76191 38.9709 8.90335C39.335 9.04478 39.6632 9.26526 39.9317 9.54889C40.2003 9.83251 40.4026 10.1722 40.524 10.5435C40.6454 10.9148 40.6829 11.3083 40.6338 11.6959C40.6338 13.6021 39.6035 14.6979 37.8262 14.6979H35.6709V8.73101H37.8262ZM36.5976 13.854H37.7226C38.0011 13.8707 38.2797 13.825 38.5382 13.7204C38.7967 13.6158 39.0287 13.4548 39.2172 13.2493C39.4057 13.0437 39.546 12.7987 39.6278 12.5321C39.7097 12.2655 39.7311 11.9839 39.6904 11.708C39.7281 11.4332 39.7046 11.1534 39.6215 10.8887C39.5383 10.6241 39.3977 10.3811 39.2096 10.1771C39.0216 9.97322 38.7907 9.81341 38.5337 9.70917C38.2766 9.60494 37.9996 9.55885 37.7226 9.57422H36.5976V13.854Z"
          fill={accentColor}
        />
        <path
          d="M41.6807 12.4443C41.6524 12.1484 41.6862 11.8499 41.7801 11.5678C41.8739 11.2857 42.0257 11.0264 42.2256 10.8064C42.4256 10.5864 42.6693 10.4107 42.9411 10.2904C43.213 10.1701 43.507 10.108 43.8042 10.108C44.1015 10.108 44.3955 10.1701 44.6673 10.2904C44.9392 10.4107 45.1829 10.5864 45.3828 10.8064C45.5828 11.0264 45.7345 11.2857 45.8284 11.5678C45.9222 11.8499 45.9561 12.1484 45.9278 12.4443C45.9566 12.7406 45.9232 13.0396 45.8296 13.3221C45.736 13.6046 45.5843 13.8644 45.3843 14.0848C45.1843 14.3052 44.9404 14.4814 44.6683 14.6019C44.3962 14.7225 44.1019 14.7847 43.8042 14.7847C43.5066 14.7847 43.2123 14.7225 42.9402 14.6019C42.668 14.4814 42.4241 14.3052 42.2241 14.0848C42.0242 13.8644 41.8725 13.6046 41.7789 13.3221C41.6853 13.0396 41.6519 12.7406 41.6807 12.4443ZM45.0137 12.4443C45.0137 11.4683 44.5752 10.8975 43.8057 10.8975C43.0332 10.8975 42.5987 11.4683 42.5987 12.4444C42.5987 13.4282 43.0333 13.9946 43.8057 13.9946C44.5752 13.9946 45.0137 13.4243 45.0137 12.4443H45.0137Z"
          fill={accentColor}
        />
        <path
          d="M51.5732 14.6978H50.6514L49.7207 11.3813H49.6504L48.7236 14.6978H47.8105L46.5693 10.1948H47.4707L48.2773 13.6308H48.3438L49.2695 10.1948H50.1221L51.0479 13.6308H51.1182L51.9209 10.1948H52.8096L51.5732 14.6978Z"
          fill={accentColor}
        />
        <path
          d="M53.8535 10.1948H54.709V10.9101H54.7754C54.888 10.6532 55.0781 10.4378 55.3189 10.294C55.5598 10.1502 55.8395 10.0852 56.1191 10.1079C56.3382 10.0914 56.5582 10.1244 56.7628 10.2045C56.9674 10.2846 57.1514 10.4097 57.3011 10.5706C57.4508 10.7314 57.5623 10.9239 57.6275 11.1337C57.6927 11.3435 57.7099 11.5653 57.6777 11.7827V14.6977H56.7891V12.0058C56.7891 11.2822 56.4746 10.9223 55.8174 10.9223C55.6686 10.9154 55.5201 10.9407 55.3821 10.9966C55.244 11.0524 55.1197 11.1374 55.0175 11.2458C54.9154 11.3542 54.8379 11.4834 54.7904 11.6245C54.7429 11.7656 54.7264 11.9154 54.7422 12.0635V14.6977H53.8535L53.8535 10.1948Z"
          fill={accentColor}
        />
        <path
          d="M59.0938 8.43701H59.9824V14.6978H59.0938V8.43701Z"
          fill={accentColor}
        />
        <path
          d="M61.2178 12.4444C61.1895 12.1484 61.2234 11.8499 61.3172 11.5678C61.4111 11.2857 61.5629 11.0263 61.7628 10.8064C61.9628 10.5864 62.2065 10.4106 62.4784 10.2903C62.7503 10.17 63.0443 10.1079 63.3416 10.1079C63.6389 10.1079 63.9329 10.17 64.2047 10.2903C64.4766 10.4106 64.7203 10.5864 64.9203 10.8064C65.1203 11.0263 65.272 11.2857 65.3659 11.5678C65.4598 11.8499 65.4936 12.1484 65.4653 12.4444C65.4942 12.7406 65.4607 13.0396 65.3671 13.3221C65.2734 13.6046 65.1217 13.8645 64.9217 14.0849C64.7217 14.3053 64.4778 14.4814 64.2057 14.6019C63.9335 14.7225 63.6392 14.7847 63.3416 14.7847C63.0439 14.7847 62.7496 14.7225 62.4775 14.6019C62.2053 14.4814 61.9614 14.3053 61.7614 14.0849C61.5614 13.8645 61.4097 13.6046 61.3161 13.3221C61.2225 13.0396 61.189 12.7406 61.2178 12.4444ZM64.5508 12.4444C64.5508 11.4683 64.1123 10.8975 63.3428 10.8975C62.5703 10.8975 62.1358 11.4683 62.1358 12.4444C62.1358 13.4283 62.5704 13.9947 63.3428 13.9947C64.1123 13.9947 64.5508 13.4244 64.5508 12.4444H64.5508Z"
          fill={accentColor}
        />
        <path
          d="M66.4009 13.4243C66.4009 12.6138 67.0044 12.1465 68.0757 12.0801L69.2954 12.0098V11.6211C69.2954 11.1455 68.981 10.877 68.3735 10.877C67.8774 10.877 67.5337 11.0591 67.4351 11.3774H66.5747C66.6655 10.604 67.3931 10.1079 68.4146 10.1079C69.5435 10.1079 70.1802 10.6699 70.1802 11.6211V14.6978H69.3247V14.0649H69.2544C69.1117 14.2919 68.9113 14.477 68.6737 14.6012C68.436 14.7254 68.1697 14.7843 67.9019 14.7719C67.7128 14.7916 67.5218 14.7714 67.341 14.7127C67.1602 14.654 66.9938 14.5581 66.8524 14.4311C66.711 14.3042 66.5977 14.149 66.52 13.9756C66.4422 13.8021 66.4016 13.6144 66.4009 13.4243ZM69.2954 13.0395V12.6631L68.1958 12.7334C67.5757 12.7749 67.2944 12.9858 67.2944 13.3828C67.2944 13.7881 67.646 14.0239 68.1294 14.0239C68.2711 14.0382 68.4142 14.0239 68.5502 13.9819C68.6862 13.9398 68.8123 13.8707 68.9211 13.7789C69.0299 13.6871 69.1191 13.5743 69.1834 13.4473C69.2477 13.3202 69.2858 13.1816 69.2954 13.0395Z"
          fill={accentColor}
        />
        <path
          d="M71.3481 12.4444C71.3481 11.0215 72.0796 10.1201 73.2173 10.1201C73.4987 10.1072 73.778 10.1746 74.0225 10.3145C74.267 10.4544 74.4667 10.661 74.5981 10.9101H74.6646V8.43701H75.5532V14.6978H74.7017V13.9863H74.6314C74.4897 14.2338 74.2831 14.4378 74.0339 14.5763C73.7846 14.7148 73.5022 14.7825 73.2173 14.772C72.0718 14.772 71.3481 13.8706 71.3481 12.4444ZM72.2661 12.4444C72.2661 13.3994 72.7163 13.9741 73.4693 13.9741C74.2183 13.9741 74.6812 13.3911 74.6812 12.4483C74.6812 11.5098 74.2134 10.9185 73.4693 10.9185C72.7212 10.9185 72.2661 11.4971 72.2661 12.4444H72.2661Z"
          fill={accentColor}
        />
        <path
          d="M79.23 12.4443C79.2017 12.1484 79.2356 11.8499 79.3294 11.5678C79.4232 11.2857 79.575 11.0264 79.7749 10.8064C79.9749 10.5864 80.2186 10.4107 80.4904 10.2904C80.7623 10.1701 81.0563 10.108 81.3536 10.108C81.6508 10.108 81.9448 10.1701 82.2167 10.2904C82.4885 10.4107 82.7322 10.5864 82.9322 10.8064C83.1321 11.0264 83.2839 11.2857 83.3777 11.5678C83.4715 11.8499 83.5054 12.1484 83.4771 12.4443C83.5059 12.7406 83.4725 13.0396 83.3789 13.3221C83.2853 13.6046 83.1336 13.8644 82.9336 14.0848C82.7336 14.3052 82.4897 14.4814 82.2176 14.6019C81.9455 14.7225 81.6512 14.7847 81.3536 14.7847C81.0559 14.7847 80.7616 14.7225 80.4895 14.6019C80.2173 14.4814 79.9735 14.3052 79.7735 14.0848C79.5735 13.8644 79.4218 13.6046 79.3282 13.3221C79.2346 13.0396 79.2012 12.7406 79.23 12.4443ZM82.563 12.4443C82.563 11.4683 82.1245 10.8975 81.355 10.8975C80.5826 10.8975 80.148 11.4683 80.148 12.4444C80.148 13.4282 80.5826 13.9946 81.355 13.9946C82.1245 13.9946 82.563 13.4243 82.563 12.4443Z"
          fill={accentColor}
        />
        <path
          d="M84.6694 10.1948H85.5249V10.9101H85.5913C85.704 10.6532 85.894 10.4378 86.1348 10.294C86.3757 10.1502 86.6555 10.0852 86.9351 10.1079C87.1542 10.0914 87.3742 10.1244 87.5788 10.2045C87.7834 10.2846 87.9673 10.4097 88.117 10.5706C88.2667 10.7314 88.3783 10.9239 88.4435 11.1337C88.5087 11.3435 88.5258 11.5653 88.4937 11.7827V14.6977H87.605V12.0058C87.605 11.2822 87.2905 10.9223 86.6333 10.9223C86.4846 10.9154 86.336 10.9407 86.198 10.9966C86.0599 11.0524 85.9356 11.1374 85.8335 11.2458C85.7313 11.3542 85.6539 11.4834 85.6063 11.6245C85.5588 11.7656 85.5423 11.9154 85.5581 12.0635V14.6977H84.6694V10.1948Z"
          fill={accentColor}
        />
        <path
          d="M93.5151 9.07373V10.2153H94.4907V10.9639H93.5151V13.2793C93.5151 13.751 93.7095 13.9575 94.1519 13.9575C94.2651 13.9572 94.3783 13.9503 94.4907 13.937V14.6772C94.3311 14.7058 94.1694 14.721 94.0073 14.7226C93.019 14.7226 92.6255 14.375 92.6255 13.5068V10.9638H91.9106V10.2153H92.6255V9.07373H93.5151Z"
          fill={accentColor}
        />
        <path
          d="M95.7046 8.43701H96.5854V10.9185H96.6558C96.7739 10.6591 96.9691 10.4425 97.2148 10.2982C97.4605 10.1539 97.7447 10.0888 98.0288 10.1118C98.2467 10.1 98.4646 10.1364 98.6669 10.2184C98.8692 10.3004 99.0508 10.4261 99.199 10.5864C99.3471 10.7468 99.458 10.9378 99.5238 11.146C99.5896 11.3541 99.6086 11.5742 99.5795 11.7905V14.6978H98.69V12.0098C98.69 11.2905 98.355 10.9263 97.7271 10.9263C97.5743 10.9137 97.4207 10.9347 97.2769 10.9878C97.1332 11.0408 97.0027 11.1247 96.8947 11.2334C96.7867 11.3421 96.7038 11.4732 96.6517 11.6173C96.5997 11.7614 96.5797 11.9152 96.5933 12.0679V14.6977H95.7046L95.7046 8.43701Z"
          fill={accentColor}
        />
        <path
          d="M104.761 13.4819C104.64 13.8935 104.379 14.2495 104.022 14.4876C103.665 14.7258 103.236 14.8309 102.81 14.7847C102.513 14.7925 102.219 14.7357 101.946 14.6182C101.674 14.5006 101.43 14.3252 101.232 14.1041C101.034 13.8829 100.887 13.6214 100.8 13.3376C100.713 13.0537 100.689 12.7544 100.73 12.4605C100.69 12.1656 100.715 11.8656 100.801 11.581C100.888 11.2963 101.034 11.0335 101.231 10.8105C101.428 10.5874 101.671 10.4092 101.942 10.288C102.214 10.1668 102.509 10.1054 102.806 10.1079C104.059 10.1079 104.815 10.9639 104.815 12.3779V12.688H101.635V12.7378C101.621 12.9031 101.642 13.0694 101.696 13.2261C101.75 13.3829 101.837 13.5266 101.95 13.6481C102.062 13.7695 102.199 13.866 102.352 13.9314C102.504 13.9968 102.669 14.0297 102.834 14.0278C103.047 14.0533 103.262 14.0151 103.453 13.9178C103.644 13.8206 103.801 13.6689 103.906 13.4819L104.761 13.4819ZM101.635 12.0308H103.91C103.921 11.8796 103.9 11.7278 103.849 11.5851C103.798 11.4424 103.718 11.3119 103.614 11.2021C103.509 11.0922 103.383 11.0054 103.243 10.9472C103.103 10.8891 102.953 10.8608 102.801 10.8643C102.648 10.8623 102.495 10.8912 102.353 10.9491C102.21 11.0071 102.081 11.0929 101.972 11.2017C101.864 11.3104 101.778 11.4397 101.72 11.5821C101.662 11.7245 101.633 11.8771 101.635 12.0308H101.635Z"
          fill={accentColor}
        />
      </g>
      <defs>
        <clipPath>
          <rect width={width} height={height} fill={accentColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
