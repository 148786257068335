import { Route, Routes } from 'react-router-dom';

import { APP_PATHS } from '@shared/config/navigation';
import { MainPage } from '@pages/main';
import { AboutPage } from '@pages/about';
import { DownloadPage } from '@pages/download';
import { PrivacyPolicyPage } from '@pages/privacy-policy';
import { SharePageConnector } from '@pages/share';
import { ErrorPage } from '@pages/error';

export const RootNavigator = () => {
  return (
    <Routes>
      <Route path={APP_PATHS.main} element={<MainPage />} />
      <Route path={APP_PATHS.about} element={<AboutPage />} />
      <Route path={APP_PATHS.download} element={<DownloadPage />} />
      <Route path={APP_PATHS.privacy} element={<PrivacyPolicyPage />} />
      <Route path={APP_PATHS.share} element={<SharePageConnector />} />
      <Route path="*" element={<ErrorPage errorNumber={404} />} />
    </Routes>
  );
};
