import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { RootNavigator } from '@processes/navigation';
import { GlobalStyles, styled, theme } from '@shared/ui/theme';
import { HeaderDropdownConnector } from '@widgets/header-dropdown';

const RootWrapper = styled.main`
  width: 100%;
  min-width: 360px;
  color: ${({ theme }) => theme.colors.content.primary.main};
  overflow: hidden;
`;

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HeaderDropdownConnector />
        <RootWrapper>
          <GlobalStyles />
          <RootNavigator />
        </RootWrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
