import { styled, useTheme } from '@shared/ui/theme';
import { Body2, HeaderLarge } from '@shared/ui/typography';
import { IconDownloadAppStoreSmall } from '@shared/ui/icons';
import { SectionTemplate } from '@shared/ui/core';
import { APP_LINKS } from '@shared/config/constants';
import { FooterConnector } from '@widgets/footer';

import AppExampleImage from '@shared/ui/images/wallet-photo-app.png';

import { downloadPageContent } from '../../mock';

const Image = styled.img`
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 420px;
  max-width: 600px;
  object-fit: cover;
`;

const ContentWrapper = styled(SectionTemplate)`
  align-items: center;
  text-align: center;
`;

const ButtonLink = styled.a`
  display: flex;
  margin-top: 40px;
`;

export const DownloadPage = () => {
  const theme = useTheme();

  return (
    <>
      <Image
        src={AppExampleImage}
        alt={'WalletPhoto app'}
        width={360}
        height={420}
      />
      <ContentWrapper>
        <HeaderLarge>WalletPhoto+</HeaderLarge>
        <Body2 color={theme.colors.content.secondary.main}>
          {downloadPageContent.text}
        </Body2>

        <ButtonLink href={APP_LINKS.mobileLinks.ios}>
          <IconDownloadAppStoreSmall />
        </ButtonLink>
      </ContentWrapper>

      <FooterConnector />
    </>
  );
};
