import { ComponentProps } from 'react';

import { styled } from '@shared/ui/theme';

import { BurgerButton, LogoLink } from '../../atoms';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

type TBurgerProps = ComponentProps<typeof BurgerButton>;

type Props = {
  burgerProps: TBurgerProps;
  onLogoClick?: () => void;
};

export const Header = ({ burgerProps, onLogoClick }: Props) => {
  return (
    <Wrapper>
      <LogoLink onClick={onLogoClick} />
      <BurgerButton {...burgerProps} />
    </Wrapper>
  );
};
