import useAxios from 'axios-hooks';

import { apiBasePath } from '@shared/config/constants';

export const useGetPass = (shareId: string) => {
  const options = {
    method: 'GET',
    url: `${apiBasePath}/${process.env.NODE_ENV}/passes/share/${shareId}`,
    headers: { 'Content-Type': 'application/json' },
  };

  return useAxios(options);
};
